import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const savedCurrency = localStorage.getItem('selectedCurrency') || 'USD';
const initialState = {
    rates: {},
    selectedCurrency: savedCurrency,
    status: 'idle',
    error: null
};
const currencySlice = createSlice({
    name: 'currency',
    initialState, 
    reducers: {
        setSelectedCurrency: (state, action) => {
            // console.log("action.payload", action.payload)
            state.selectedCurrency = action.payload;
            localStorage.setItem('selectedCurrency', action.payload);
        },
        setCurrencyRates: (state, action) => {
            // console.log("rate", action.payload)
            state.rates = action.payload;
            state.status = 'succeeded';
        },
        setLoading: (state) => {
            state.status = 'loading';
        },
        setError: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        }
    },
})

export const {
    setSelectedCurrency,
    setCurrencyRates,
    setLoading,
    setError
} = currencySlice.actions;


export const fetchCurrency = (baseCode = savedCurrency) => async (dispatch) => {
    // dispatch(setIsLoading());
    console.log(baseCode,"212")
    // cur =istrue? fn ():  
    dispatch(setLoading());
    try {
        const response = await axios.get(`https://v6.exchangerate-api.com/v6/691735a8a59d144456cae622/latest/${baseCode}`);

        if (response.data.result === "success") {
            dispatch(setCurrencyRates(response.data.conversion_rates));
        } else {
            dispatch(setError(response.data.message));
            toast.error(response.data.message);
        }
    } catch (error) {
        dispatch(setError(error.message));
        toast.error("Failed to fetch currency rates");
        console.log("error currency fetch", error);
    }
};

export default currencySlice.reducer;