import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import product from "../assets/images/product-img-01.png";
import product2 from "../assets/images/product-img-02.png";
import product3 from "../assets/images/product-img-03.png";
import product4 from "../assets/images/product-img-04.png";
import product5 from "../assets/images/product-img-05.png";
import product6 from "../assets/images/product-img-06.png";
import product7 from "../assets/images/product-img-07.png";
import product8 from "../assets/images/product-img-08.png";
import product9 from "../assets/images/product-img-09.png";
import product10 from "../assets/images/product-img-10.png";
import product11 from "../assets/images/product-img-11.png";
import product12 from "../assets/images/product-img-12.png";
import check from "../assets/images/check.svg";
import OwlCarousel from 'react-owl-carousel';
import ThumbSlider from './Sliders/ThumbSlider';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleProducts } from './features/productSlice';
const ProductDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const singleProductData = useSelector((state) => state.product.singleProduct);
    console.log(singleProductData)
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchSingleProducts(id))
    }, [])
    const gramPrices = {
        '20 GM': 28.85,
        '50 GM': 33.85,
        '100 GM': 45.99,
        '150 GM': 58.75,
        '250 GM': 72.50,
        '500 GM': 125.00,
        '1 KG': 230.00
    };
    const [selectedGram, setSelectedGram] = useState('100 GM');
    const [currentPrice, setCurrentPrice] = useState(gramPrices['100 GM']);

    // Handle gram selection
    const handleGramSelect = (gram) => {
        setSelectedGram(gram);
        setCurrentPrice(gramPrices[gram]);
    };
    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: true
            },
            768: {
                items: 3,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 5,
                nav: true
            }
        }
    };
    const options1 = {
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: true
            },
            768: {
                items: 3,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 5,
                nav: true
            }
        }
    };

    const [quantity, setQuantity] = useState(0);
    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1)
        }
    }
    const handleIncrement = () => {
        setQuantity(quantity + 1);
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuantity(value)
    }

    const [isExpended, setIsExpended] = useState(false);

    const toggleReadMore = () => {
        setIsExpended(!isExpended)
    }

    // // Calculate final price
    // const [quantity, setQuantity] = useState(1);
    // const handleInputChange = (e) => {
    //     const value = parseInt(e.target.value);
    //     if (value >= 1) {
    //       setQuantity(value);
    //     }
    //   };
    // const calculatePrice = () => {
    //     return (basePrice * quantity).toFixed(2);
    // };
    return (
        <>
            <section className="product-detail tp-space mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        {
                           singleProductData && singleProductData?.product_images && (
                                < ThumbSlider singleProductData={singleProductData?.product_images} />
                            )
                        }
                        <div className="col-lg-6">
                            <div className="product-detail-content">
                                <p className="auth">
                                    {singleProductData?.authentic && (
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                    )}
                                    {
                                        singleProductData?.in_stock && (
                                            <span className="text-success">
                                                <i className="fa fa-check me-1" />
                                                In stock
                                            </span>
                                        )
                                    }

                                </p>
                                <h4>{singleProductData?.product_name}</h4>
                                <p>Brand : {singleProductData?.brand_id?.brand_name}</p>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                    (32 reviews)
                                </p>
                                <p className="price">
                                    {singleProductData?.price} <del>{singleProductData?.is_discount && (singleProductData.discount_price)}</del>
                                    {/* ${currentPrice.toFixed(2)} <del>${(currentPrice * 1.15).toFixed(2)}</del> */}
                                </p>
                                <h6 className="fz-16 text-dark fw-400">Available in:</h6>
                                <div className="quantity-btn">
                                    {/* {Object.keys(gramPrices).map((gram) => (
                                        <a
                                            key={gram}
                                            href="javascript:void(0);"
                                            className={`me-1 ${selectedGram === gram ? 'select' : ''}`}
                                            onClick={() => handleGramSelect(gram)}
                                        >
                                            {gram}
                                        </a>
                                    ))} */}
                                    <a href="javascript:void(0);" className='me-1'>20 GM</a>
                                    <a href="javascript:void(0);" className='me-1'>50 GM</a>
                                    <a href="javascript:void(0);" className="select me-1">
                                        100 GM
                                    </a>
                                    <a href="javascript:void(0);" className='me-1'>150 GM</a>
                                    <a href="javascript:void(0);" className='me-1'>250 GM</a>
                                    <a href="javascript:void(0);" className='me-1'>500 GM</a>
                                    <a href="javascript:void(0);" className='me-1'>1 KG</a>
                                </div>
                                <div className="quantity-main ">
                                    <div className="quantity">
                                        <button type="button" className="minus" onClick={handleDecrement}>
                                            <i className="fa fa-minus" />
                                        </button>
                                        <input className="number" type="number" value={quantity}
                                            onChange={handleInputChange} />
                                        <button type="button" className="plus" onClick={handleIncrement}>
                                            <i className="far fa-plus" />
                                        </button>
                                    </div>
                                    <button className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </button>
                                    <button className="fav-btn">
                                        <i className="fa-regular fa-heart" />
                                    </button>
                                </div>
                                <div className="share-btn">
                                    <a href="javascript:void(0);" className="outline-btn">
                                        <i className="fas fa-share me-2" />
                                        Share
                                    </a>
                                    <a
                                        href="javascript:void(0);"
                                        data-bs-toggle="modal"
                                        data-bs-target="#ingredients"
                                        className="txt-org fw-500"
                                    >
                                        Ingredients
                                    </a>
                                </div>
                                <h6 className="fz-16 text-dark fw-400 d-flex justify-content-between">
                                    <span>Available in</span>
                                    <a href="javascript:void(0);" className="txt-org">
                                        <i className="fa fa-exclamation-circle" />
                                    </a>
                                </h6>
                                <p>
                                   {singleProductData?.description}{" "}
                                    <span>
                                        {isExpended ? (
                                            <span>
                                                {/* Show the full content when expanded */}
                                                Extra content revealed after clicking "Read More"!{" "}
                                                <a href="javascript:void(0);" className="txt-org" onClick={toggleReadMore}>
                                                    Read Less
                                                </a>
                                            </span>
                                        ) : (
                                            <a href="javascript:void(0);" className="txt-org" onClick={toggleReadMore}>
                                                Read More
                                            </a>
                                        )
                                        }
                                    </span>
                                    {/* <a href="javascript:void(0);" className="txt-org">
                                        Read More
                                    </a> */}
                                </p>
                            </div>
                        </div>
                        <div
                            className="modal fade ingredients-popup"
                            id="ingredients"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Ingredients
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <i className="fa fa-times" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Ingredients</td>
                                                    <td>
                                                        Lemon, Red Chilli Powder, Cold Pressed Groundnut Oil
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Shelf Life</td>
                                                    <td>180 Days</td>
                                                </tr>
                                                <tr>
                                                    <td>Packing</td>
                                                    <td>Packed in Sealed Jar to Retain Freshness</td>
                                                </tr>
                                                <tr>
                                                    <td>Sourced From</td>
                                                    <td>Tamil Nadus</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="product-details-tabs">
                                <ul className="nav">
                                    <li className="tab-item">
                                        <button
                                            className="tab-link active"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab-01"
                                            type="button"
                                            role="tab"
                                        >
                                            Description
                                        </button>
                                    </li>
                                    <li className="tab-item">
                                        <button
                                            className="tab-link"
                                            data-bs-toggle="tab"
                                            data-bs-target="#tab-03"
                                            type="button"
                                            role="tab"
                                        >
                                            Reviews
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab-01"
                                        role="tabpanel"
                                    >
                                        <p>
                                            {singleProductData?.sort_description}
                                        </p>
                                        <h6 className="fz-16 fw-500">Specifications</h6>
                                        <p>
                                            Diamond Clarity: <span>SI2</span>
                                        </p>
                                        <p>
                                            Diamond Color: <span>G-H</span>
                                        </p>
                                        <p>
                                            No Of Diamonds: <span>33</span>
                                        </p>
                                        <p>
                                            Brand: <span>Tanishq</span>
                                        </p>
                                        <p>
                                            Collection: <span>Bestsellers</span>
                                        </p>
                                    </div>
                                    <div className="tab-pane fade" id="tab-03" role="tabpanel">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <div className="rating-card">
                                                    <h4 className="title">Ratings &amp; Reviews</h4>
                                                    <p className="rating">
                                                        <span className="fas fa-star" />
                                                        <span className="fas fa-star" />
                                                        <span className="fas fa-star" />
                                                        <span className="fas fa-star" />
                                                        <span className="fa-regular fa-star" />
                                                        (32 reviews)
                                                    </p>
                                                    <div className="rating-bar">
                                                        <span>
                                                            5 <i className="fas fa-star" />
                                                        </span>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar grn"
                                                                role="progressbar"
                                                                style={{ width: "90%" }}
                                                            />
                                                        </div>
                                                        <span>256</span>
                                                    </div>
                                                    <div className="rating-bar">
                                                        <span>
                                                            4 <i className="fas fa-star" />
                                                        </span>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar lgt-grn"
                                                                role="progressbar"
                                                                style={{ width: "70%" }}
                                                            />
                                                        </div>
                                                        <span>220</span>
                                                    </div>
                                                    <div className="rating-bar">
                                                        <span>
                                                            3 <i className="fas fa-star" />
                                                        </span>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar yllw"
                                                                role="progressbar"
                                                                style={{ width: "55%" }}
                                                            />
                                                        </div>
                                                        <span>110</span>
                                                    </div>
                                                    <div className="rating-bar">
                                                        <span>
                                                            2 <i className="fas fa-star" />
                                                        </span>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar org"
                                                                role="progressbar"
                                                                style={{ width: "40%" }}
                                                            />
                                                        </div>
                                                        <span>47</span>
                                                    </div>
                                                    <div className="rating-bar">
                                                        <span>
                                                            1 <i className="fas fa-star" />
                                                        </span>
                                                        <div className="progress">
                                                            <div
                                                                className="progress-bar rd"
                                                                role="progressbar"
                                                                style={{ width: "25%" }}
                                                                aria-valuenow={25}
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            />
                                                        </div>
                                                        <span>15</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-6">
                                                <div className="product-review">
                                                    <p className="rating-txt">
                                                        <span className="rating">
                                                            {" "}
                                                            <em>
                                                                4.9 <i className="fas fa-star" />
                                                            </em>
                                                        </span>
                                                        <span>Rithika Minna Jan 23, 2022</span>
                                                    </p>
                                                    <p className="desc-txt">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        Tempor sit sed ultricies sit ultricies sagittis nam
                                                        vestibulum malesuada. Amet fusce in vitae arcu, elit.
                                                        Tellus amet congue sit ac sed. Eget lacinia massa
                                                        tristique vitae enim amet, at ultrices id...{" "}
                                                        <a href="javascript:void(0)'">Read More</a>
                                                    </p>
                                                    <img src={thumb} alt="thumb" />
                                                    <img src={thumb} alt="thumb" />
                                                </div>
                                                <div className="product-review">
                                                    <p className="rating-txt">
                                                        <span className="rating">
                                                            {" "}
                                                            <em>
                                                                4.9 <i className="fas fa-star" />
                                                            </em>
                                                        </span>
                                                        <span>Rithika Minna Jan 23, 2022</span>
                                                    </p>
                                                    <p className="desc-txt">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                        Tempor sit sed ultricies sit ultricies sagittis nam
                                                        vestibulum malesuada. Amet fusce in vitae arcu, elit.
                                                        Tellus amet congue sit ac sed. Eget lacinia massa
                                                        tristique vitae enim amet, at ultrices id...{" "}
                                                        <a href="javascript:void(0)'">Read More</a>
                                                    </p>
                                                    <img src={thumb} alt="thumb" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-sec tp-space pt-0">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Similar <span>Products</span>
                        </h3>
                        <a href="javascript:void(0);" className="thm-btn light">
                            View More
                        </a>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options}>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product2} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product3} alt="product" />
                                <span className="off">18% OFF</span>
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-danger">
                                        <i className="fa fa-check me-1" />
                                        Out stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product4} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product5} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-danger">
                                        <i className="fa fa-check me-1" />
                                        Out stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product6} alt="product" />
                                <span className="off">18% OFF</span>
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product4} alt="" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            <section className="product-sec tp-space pt-0">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Premium <span>Versions</span>
                        </h3>
                        <a href="javascript:void(0);" className="thm-btn light">
                            View More
                        </a>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options1}>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product7} alt="product" />
                                <span className="off">18% OFF</span>
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product8} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product9} alt="product" />
                                <span className="off">18% OFF</span>
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-danger">
                                        <i className="fa fa-check me-1" />
                                        Out stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product10} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product11} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-danger">
                                        <i className="fa fa-check me-1" />
                                        Out stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product12} alt="product" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="product-card">
                            <div className="product-img">
                                <img src={product} alt="" />
                            </div>
                            <div className="product-content">
                                <p className="auth">
                                    <span className='d-flex'>
                                        <img src={check} className="me-1" alt="check" />
                                        Authentic{" "}
                                    </span>
                                    <span className="text-success">
                                        <i className="fa fa-check me-1" />
                                        In stock
                                    </span>
                                </p>
                                <h5>
                                    <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                </h5>
                                <p className="rating">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <p className="price">
                                    $28.85 <del>$33.85</del>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <a href="javascript:void(0);" className="fav-btn">
                                        <i className="fa-regular fa-heart " />
                                    </a>
                                    <a href="javascript:void(0);" className="thm-btn">
                                        <i className="fas fa-shopping-basket me-1" />
                                        Add to Cart
                                    </a>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
        </>
    )
}

export default ProductDetails