import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({component:Component}) => {
    const navigate = useNavigate();
  
    // useEffect(() => {
    //   const validateToken = async () => {
    //     const token = localStorage.getItem("token");
    //     if (!token) {
    //       navigate("/login",{ replace: true });
    //       return;
    //     }
    //   };
    //   validateToken();
    // }, [navigate]);
  return <Component />
}

export default ProtectedRoute