import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import coupon from "../assets/images/cupon-icon.svg"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCartItemApi, fetchCartProducts, removeFromCart, updateCartQuantity, updateCartQuantityAPI, updateQuantity } from './features/cartSlice'
import { toast } from 'react-toastify'
import { baseUrlAdmin, imgUrl } from './Helper/BaseUrl'
import axios from 'axios'
import PriceDisplay from './PriceDisplay'

const Cart = () => {
  const [loadingItems, setLoadingItems] = useState({} );
  const dispatch = useDispatch();
  const token = localStorage.getItem("token")
  // console.log(token)
  const { items, loading, error } = useSelector(state => state.cart);
  // console.log("items", items)
  const [coupons, setCoupons] = useState([]);
  // const [loading, setLoading] = useState(false);

  const fetchCoupons = async () => {
    try {
      // setLoading(true);
      const response = await axios.get(`${baseUrlAdmin}/coupon-list`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // console.log(response.data)
      setCoupons(response.data.data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    } finally {
      // setLoading(false);
    }
  };
  const copyToClipboard = (couponCode) => {
    navigator.clipboard.writeText(couponCode)
      .then(() => {
        toast.success('Coupon code copied!');
      })
      .catch((err) => {
        toast.error('Failed to copy coupon code');
        console.error('Copy failed:', err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCartProducts())
    fetchCoupons();
  }, [dispatch]);
  // if (loading) {
  //   return <div className='loader-main'>
  //     <div className="loaderaa">
  //     </div>
  //   </div>
  // }

  const handleIncreaseQuantity = async (productId, currentQuantity,stockQty) => {
    if (currentQuantity >= stockQty) {
      toast.info("Cannot add more items than available stock");
      return;
    }

    const newQuantity = currentQuantity + 1;

    // Set loading state for this specific item
    setLoadingItems(prev => ({
      ...prev,
      [productId]: { ...prev[productId], increase: true }
    }));

    try {
      // Optimistically update the UI
      dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
      // Make the API call
      await dispatch(updateCartQuantityAPI(productId, newQuantity));
    } catch (error) {
      toast.error("Failed to update quantity");
    } finally {
      setLoadingItems(prev => ({
        ...prev,
        [productId]: { ...prev[productId], increase: false }
      }));
    }
  };

  const handleDecreaseQuantity = async (productId, currentQuantity) => {
    if (currentQuantity > 1) {
      setLoadingItems(prev => ({
        ...prev,
        [productId]: { ...prev[productId], decrease: true }
      }));

      try {
        const newQuantity = currentQuantity - 1;
        dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
        await dispatch(updateCartQuantityAPI(productId, newQuantity));
      } catch (error) {
        toast.error("Failed to update quantity");
      } finally {
        setLoadingItems(prev => ({
          ...prev,
          [productId]: { ...prev[productId], decrease: false }
        }));
      }
    }
  };

  const handleInputChange = async (productId, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 1) {
      // Optimistically update the UI
      setLoadingItems(prev => ({ ...prev, [productId]: true }));

      try {
        dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
        await dispatch(updateCartQuantityAPI(productId, newQuantity));
      } catch (error) {
        toast.error("Failed to update quantity");
      } finally {
        setLoadingItems(prev => ({ ...prev, [productId]: false }));
      }
    }
  };

  if (error) {
    return <div className="error">{toast.error(error)}</div>;
  }

  const handleRemoveItem = (productId) => {
    dispatch(deleteCartItemApi({ productId }))
  }

  // Calculate total price
  const calculateTotal = () => {
    return items.reduce((total, item) => total + (item.totalPrice), 0).toFixed(2);
  };

  return (
    <>
      <section className="banner-innr mt-150">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="banner-innr-content">
                <h3>Shopping cart</h3>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Shopping cart
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cart-sec tp-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cart-card">
                {items && items.length === 0 ? (
                  <div className='text-center'>
                    <h1>Your cart is empty.</h1>
                    <Link to='/shop' style={{ borderRadius: "50px" }} className='thm-btn mt-2'>Continue Shopping</Link>
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="table table">
                      <thead>
                        <tr>
                          <th>Product Image</th>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>total Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map(item => {
                          // console.log(item.quantity)
                          return (
                            (
                              <tr key={item._id}>
                                <td>
                                  <img
                                    src={`${imgUrl}/${item?.product_id?.product_images[0]?.image}`}
                                    className="img-50"
                                    alt="product"
                                  />
                                </td>
                                <td>{item?.name}</td>
                                <td>
                                  <div className="quantity">
                                    <button
                                      type="button"
                                      className="minus"
                                      onClick={() => handleDecreaseQuantity(item?.product_id?._id, item.quantity)}
                                      disabled={loadingItems[item?.product_id?._id]?.decrease}
                                    >
                                      {loadingItems[item?.product_id?._id]?.decrease ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="visually-hidden">Loading...</span>
                                        </div>
                                      ) : (
                                        <i className="fas fa-minus" />
                                      )}
                                    </button>
                                    <input
                                      className="number"
                                      type="number"
                                      value={item.quantity}
                                      onChange={(e) => handleInputChange(item?.product_id?._id, e)}
                                      disabled={loadingItems[item?.product_id?._id]}
                                      max={item?.product_id?.stock_qty}
                                    />
                                    <button
                                      type="button"
                                      className="plus"
                                      onClick={() => handleIncreaseQuantity(item?.product_id?._id, item.quantity, item.product_id?.stock_qty)}
                                      // disabled={loadingItems[item?.product_id?._id]?.increase || item.quantity >= item?.product_id?.stock_qty}
                                      
                                    >
                                      {loadingItems[item?.product_id?._id]?.increase ? (
                                        <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="visually-hidden">Loading...</span>
                                        </div>
                                      ) : (
                                        <i className="far fa-plus" />
                                      )}
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <p className="price"><PriceDisplay priceUSD={item.price} />
                                  {/* <del> {item.is_discount && <PriceDisplay priceUSD={item.discount_price} />}</del> */}
                                  </p>
                                </td>
                                <td>
                                  <p className="price"><PriceDisplay priceUSD={item.totalPrice} /> </p>
                                </td>
                                <td>
                                  <button
                                    onClick={() => handleRemoveItem(item?.product_id?._id)}
                                    className="text-danger ms-1"
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                </td>
                              </tr>
                            )
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-8">
              {
                coupons && coupons.map((list, index) => {
                  return (
                    <div key={list._id} className="cupon-card m-3">
                      <h5 className='d-flex justify-content-between'>
                        <div>
                          <img src={`${imgUrl}/${list.image}`} alt="coupon" /> Copy Coupon :{" "}
                          <span className="text-success">{list.code}</span>
                        </div>
                        <button
                          className="copy-btn text-end"
                          onClick={() => copyToClipboard(list.code)}
                        >
                          <i className="far fa-copy"></i>
                        </button>
                      </h5>
                    </div>
                  )
                })
              }
            </div>
            {
              items && items.length > 0 && (
                <div className="col-lg-4">
                  <div className="cart-total">
                    <h5>
                      Subtotal: <span>{}
                        <PriceDisplay priceUSD={calculateTotal()} />
                      </span>
                    </h5>
                    <p>Tax included and shipping calculated at checkout</p>
                    <Link
                      to="/checkout"
                      className="thm-btn w-100 text-center org rounded fs-6"
                    >
                      Checkout
                    </Link>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </section>
    </>

  )
}

export default Cart