import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.png'
import hugeicons from "../../assets/images/hugeicons.svg"
import searchproduct from "../../assets/images/search-product.png"
import { Link, useNavigate } from 'react-router-dom';
import LoginModal from '../Login/LoginModal';
import RegisterModal from '../Login/RegisterModal';
import ForgotPasswordModal from '../Login/ForgotPasswordModal';
import NewPasswordModal from '../Login/NewPasswordModal';
import VerifyPasswordModal from '../Login/VerifyPasswordModal';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/authSlice';
import { toast } from 'react-toastify';
import { fetchWishlist } from '../features/wishlistSlice';
import { fetchCartProducts } from '../features/cartSlice';

const Header = () => {
    const [mobileno, setmobileno] = useState('')
    const closeOffcanvas = () => {
        const offcanvasElement = document.getElementById('offcanvasRightLabel');
        offcanvasElement.click()
    };
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const cartItems = useSelector(state => state.cart.items)

    const wishlistItem = useSelector(state => state.wishlist?.wishlistItems || [])
    const { user, token } = useSelector((state) => state.auth);
    // console.log("header", cartItems)
    useEffect(() => {
        if (token && user?._id) {
            dispatch(fetchWishlist());
            dispatch(fetchCartProducts())
        }
    }, [dispatch, token, user]);
    const wishlistCount = token && wishlistItem?.products ? wishlistItem?.products?.length : 0;
    const cartCount = token ? cartItems.length : 0;
    const handleLogout = () => {
        dispatch(logout())
        // navigate('/')    
        window.location.href = '/'
        toast.success('Logout SuccessFully')
    }

    useEffect(() => {
        // Handler to close search results when clicking outside
        const handleClickOutside = (event) => {
            const searchBox = document.getElementById('searchResults');
            const searchInput = document.getElementById('searchInput');

            if (searchBox && !searchBox.contains(event.target) && event.target !== searchInput) {
                // Using Bootstrap's collapse API to hide the search results
                const bsCollapse = new window.bootstrap.Collapse(searchBox, {
                    toggle: false
                });
                bsCollapse.hide();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('click', handleClickOutside);

        // Cleanup event listener when component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header className="theme-header fixed-top">
                <section className="top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block d-xl-block">
                                <p className="text-dark mb-0">Add this product and get x% discount</p>
                            </div>
                            {/* <div className="col-lg-6 col-md-12 col-sm-12">
                                <ul className="top-bar-menu">
                                    <li className="top-bar-menu-innr">
                                        <div className="dropdown">
                                            <button
                                                className=" dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className="fa fa-money-bill-wave-alt me-2" />
                                                USD
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        USD
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        INR
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="top-bar-menu-innr">
                                        <div className="dropdown">
                                            <button
                                                className="dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className="fas fa-globe-asia me-2" />
                                                English
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        English
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="javascript:void(0);">
                                                        Hindi
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                  <li className="top-bar-menu-innr">
                                        <Link to="/aboutus">About</Link>
                                    </li>
                                    <li className="top-bar-menu-innr">
                                        <Link to="/contactus">Help?</Link>
                                    </li>
                                    <li className="top-bar-menu-innr">
                                        <Link to="/faq">FAQ</Link>
                                    </li>  
                                     <li className="top-bar-menu-innr">
                                        <Link to="/orderdetails">Track Order</Link>
                                    </li> 
                                </ul> 
                            </div> */}
                        </div>
                    </div>
                </section>
                <nav className="navbar navbar-expand-lg custom-navbar">
                    <div className="container">
                        <div className="navbar-brand">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                            <select name="" id="" className="form-select location">
                                {/* <option value="">Select Location</option> */}
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Gujrat">Gujrat</option>
                            </select>

                        </div>
                        <div className="navbar-nav me-auto">
                            <div className="nav-search position-relative">
                                <select name="" id="" className="form-select">
                                    <option value="">Our Delicacies</option>
                                    <option value="">Biscuits &amp; Snacks</option>
                                    <option value="">Petti</option>
                                    <option value="">Household Care</option>
                                    <option value="">Pickle</option>
                                    <option value="">Honey</option>
                                    <option value="">Masala</option>
                                    <option value="">Breakfast &amp; Dairy</option>
                                    <option value="">Grocery &amp; Staples</option>
                                    <option value="">Fruits &amp; Vegetables</option>
                                    <option value="">Beverages</option>
                                    <option value=""> Meats &amp; Seafood</option>
                                </select>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchInput"
                                    autoComplete="off"
                                    placeholder="Search products..."
                                    data-bs-toggle="collapse"
                                    data-bs-target="#searchResults"
                                    data-bs-auto-close="outside"
                                    aria-expanded="false"
                                    aria-controls="searchResults"
                                />
                                <button className="search-btn" type="button">
                                    <i className="fa fa-search" />
                                </button>
                                <div className="collapse search-bar-box" id="searchResults">
                                    <ul className="search-bar-box-list">
                                        <li>
                                            <a href="javascript:void(0);">
                                                <div className="ct-icon">
                                                    <span>
                                                        <img src={searchproduct} alt="search-product" />
                                                    </span>
                                                </div>
                                                <div className="ct-dtls">
                                                    <h6>Italian Chicken Meatballs</h6>
                                                    <p className="price">
                                                        $28.85 <del>$33.85</del>
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <div className="ct-icon">
                                                    <span>
                                                        <img src={searchproduct} alt="search-product" />
                                                    </span>
                                                </div>
                                                <div className="ct-dtls">
                                                    <h6>Italian Chicken Meatballs</h6>
                                                    <p className="price">
                                                        $28.85 <del>$33.85</del>
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <div className="ct-icon">
                                                    <span>
                                                        <img src={searchproduct} alt="search-product" />
                                                    </span>
                                                </div>
                                                <div className="ct-dtls">
                                                    <h6>Italian Chicken Meatballs</h6>
                                                    <p className="price">
                                                        $28.85 <del>$33.85</del>
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <div className="ct-icon">
                                                    <span>
                                                        <img src={searchproduct} alt="search-product" />
                                                    </span>
                                                </div>
                                                <div className="ct-dtls">
                                                    <h6>Italian Chicken Meatballs</h6>
                                                    <p className="price">
                                                        $28.85 <del>$33.85</del>
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <div className="ct-icon">
                                                    <span>
                                                        <img src={searchproduct} alt="search-product" />
                                                    </span>
                                                </div>
                                                <div className="ct-dtls">
                                                    <h6>Italian Chicken Meatballs</h6>
                                                    <p className="price">
                                                        $28.85 <del>$33.85</del>
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);">
                                                <div className="ct-icon">
                                                    <span>
                                                        <img src={searchproduct} alt="search-product" />
                                                    </span>
                                                </div>
                                                <div className="ct-dtls">
                                                    <h6>Italian Chicken Meatballs</h6>
                                                    <p className="price">
                                                        $28.85 <del>$33.85</del>
                                                    </p>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="nav-btn">
                            <Link to="/wishlist">
                                <i className="far fa-heart" /> <span>{wishlistCount}</span>
                            </Link>
                            <Link to="/cart">
                                <i className="fas fa-shopping-basket" /> <span>{cartCount}</span>
                            </Link>
                            <a
                                href="javascript:void(0);"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#navigation-bar"
                            >
                                <img src={hugeicons} alt="hugeicons" />
                            </a>
                        </div>
                    </div>
                </nav>
                <div
                    className="offcanvas offcanvas-end right-bar-menu"
                    tabIndex={-1}
                    id="navigation-bar"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel" data-bs-dismiss="offcanvas">
                            <i className="fa fa-arrow-circle-left me-2" />
                            Menu
                        </h5>
                        {/* <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
                    </div>
                    <div className="offcanvas-body">
                        <ul className="right-bar-menu-innr">
                            {
                                !token ? (
                                    <li>
                                        <a
                                            href="javascript:void(0);"
                                            data-bs-toggle="modal"
                                            data-bs-target="#login"
                                            onClick={closeOffcanvas}
                                        >
                                            <i className="fas fa-user me-2" />
                                            Login / Register
                                        </a>
                                    </li>
                                ) : (
                                    <li>
                                        <Link to="/profile" onClick={closeOffcanvas}>
                                            <i className="fas fa-user me-2" />
                                            Profile
                                        </Link>
                                    </li>
                                )
                            }


                            <li>
                                <Link to="/shop" onClick={closeOffcanvas}>
                                    <i className="fas fa-store me-2" />
                                    Shop
                                </Link>
                            </li>
                            <li>
                                <Link to="/category" onClick={closeOffcanvas}>
                                    <i className="fas fa-th-large me-2" />
                                    Category
                                </Link>
                            </li>
                            <li>
                                <Link to="/myorder" onClick={closeOffcanvas}>
                                    <i className="fas fa-clipboard-list me-2" />
                                    My Order
                                </Link>
                            </li>
                            <li>
                                <Link to="/wishlist" onClick={closeOffcanvas}>
                                    <i className="fas fa-heart me-2" />
                                    My Wishlist
                                </Link>
                            </li>
                            <li>
                                <Link to="/contactus" onClick={closeOffcanvas}>
                                    <i className="fa fa-headset me-2" />
                                    Help &amp; Support
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" onClick={closeOffcanvas}>
                                    <i className="fas fa-question me-2" />
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacypolicy" onClick={closeOffcanvas}>
                                    <i className="fas fa-shield me-2" />
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/termsconditions" onClick={closeOffcanvas}>
                                    <i className="fas fa-file-alt me-2" />
                                    Terms &amp; Conditions
                                </Link>
                            </li>
                            {
                                token && (
                                    <div className='text-center'>
                                        <button onClick={() => {
                                            handleLogout()
                                            closeOffcanvas()
                                        }} className='thm-btn danger mt-5'>Logout</button>
                                    </div>
                                )
                            }
                        </ul>
                        <div className="mobile-search">
                            <select name="" id="" className="form-select">
                                <option value="">Our Delicacies</option>
                                <option value="">Biscuits &amp; Snacks</option>
                                <option value="">Petti</option>
                                <option value="">Household Care</option>
                                <option value="">Pickle</option>
                                <option value="">Honey</option>
                                <option value="">Masala</option>
                                <option value="">Breakfast &amp; Dairy</option>
                                <option value="">Grocery &amp; Staples</option>
                                <option value="">Fruits &amp; Vegetables</option>
                                <option value="">Beverages</option>
                                <option value=""> Meats &amp; Seafood</option>
                            </select>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search products..."
                            />
                            <button className="search-btn" type="button">
                                <i className="fa fa-search" />
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {/* Login-modal-start */}
            <LoginModal />
            {/* Login-modal-end */}
            {/* Forgot-password-modal-start */}
            <ForgotPasswordModal />
            {/* Forgot-password-modal-end */}
            {/* New-password-modal-start */}
            <NewPasswordModal />
            {/* New-password-modal-end */}
            {/* Register-modal-start */}
            <RegisterModal setmobileno={setmobileno} />
            {/* Register-modal-end */}
            {/* Verify-password-modal-start */}
            <VerifyPasswordModal mobileno={mobileno} />
            {/* Verify-password-modal-end */}
            {/* location-mdal-start */}
            <div
                className="modal fade location-modal"
                id="location"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Select a Location
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="location-modal-bx">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Location"
                                />
                            </div>
                            <ul className="location-list">
                                <li>
                                    <a href="javascript:void(0);" className="current-address">
                                        <span className="fa fa-location loc-icon" />
                                        <div className="current-address-contnt">
                                            <h5>Use current-location</h5>
                                            <p>Wellington St., Ottawa, Ontario, Canada</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="border-0">
                                    <h6>Recent Location</h6>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" className="current-address">
                                        <span className="fa fa-map-marker-alt loc-icon" />
                                        <div className="current-address-contnt">
                                            <h5>Jaipur</h5>
                                            <p>Wellington St., Ottawa, Ontario, Canada</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="thm-btn dark"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="thm-btn">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* location-mdal-end */}
        </>
    )
}

export default Header