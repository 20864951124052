import React from 'react';
import { useDispatch } from 'react-redux';
import { setSortType } from './features/productSlice';

const ProductSort = ({ sortType }) => {
    const dispatch = useDispatch();

    const handleSortChange = (e) => {
        dispatch(setSortType(e.target.value));
    };

    return (
        <div className='d-flex align-items-center'>
            <label htmlFor="sortSelect" className='w-100'>Sort By :</label>
            <select 
                id="sortSelect"
                name="sort" 
                value={sortType}
                onChange={handleSortChange} 
                className="form-select"
            >
                <option value="">Default</option>
                <option value="lowest">Price(lowest)</option>
                <option value="highest">Price(highest)</option>
                <option value="ascending">Name(a-z)</option>
                <option value="descending">Name(z-a)</option>
            </select>
        </div>
    );
};

export default ProductSort;