import React, { useState } from 'react'
import registration from "../../assets/images/registration-img.png";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
const VerifyPasswordModal = ({ mobileno }) => {
    const [values, setValues] = useState({
        otp: "",
    });
    const [loading, setLoading] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(preValues => ({
            ...preValues, [name]: value
        }))
    }
    const validateOtp = () => {
        const { otp } = values;
        if (!otp.trim()) {
            toast.error('Please Enter Otp');
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateOtp()) {
            // console.log("000", values.otp)
            setLoading(true)
            try {
                const response = await axios.post(`${baseUrl}/register-otp-verify`, values)
                if (response.data.status) {
                    toast.success(response.data.message)
                    // Get both modals
                    const otpModalEl = document.getElementById('verify-otp');
                    const otpInstance = window.bootstrap.Modal.getInstance(otpModalEl)
                    otpInstance.hide();

                    const ShowLoginModal = document.querySelector('#login');
                    const otpModal = new window.bootstrap.Modal(ShowLoginModal);
                    otpModal.show();
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message)
                }
            } catch (error) {
                setLoading(false)
                console.log("verify otp error", error)
            }

        }
    }

    const handleClose = () => {
        const modal = document.getElementById('verify-otp');
        const bsModal = window.bootstrap.Modal.getInstance(modal);
        bsModal.hide();
        const backdrop = document.getElementById('verify-otp');
        console.log(backdrop)
        // backdrop.click()
        if (backdrop) {
            backdrop.remove();
        }
    }

    const handleResendOtp = async () => {
        try {
            const response = await axios.post(`${baseUrl}/otp-resend`, { mobile: mobileno }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            console.log("12", response)
            if (response.data.status) {
                toast.success('OTP has been resent!' || response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log('otp resend error', error)
        }
    }
    return (
        <>
            <div
                className="modal fade registration"
                id="verify-otp"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}

                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Verify OTP</h3>
                                            <p className="text-white">
                                                Enter your credentials to log into your account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Enter OTP</label>
                                                <input
                                                    type="number"
                                                    name='otp'
                                                    value={values?.otp}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter OTP"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                    
                                                </button>
                                            </div>
                                            {/* <p className="text-center fz-18">0:30S</p> */}

                                        </form>
                                        <p className="clr fz-18 text-center mt-4">
                                            <button onClick={handleResendOtp} className="txt-org fw-600">
                                                Resend
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default VerifyPasswordModal