import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import categoryIcon from "../assets/images/category-icon.svg"
import categoryIcon2 from "../assets/images/category-icon-02.svg";
import categoryIcon3 from "../assets/images/category-icon-03.svg";
import categoryIcon4 from "../assets/images/category-icon-04.svg";
import categoryIcon5 from "../assets/images/category-icon-05.svg";
import categoryIcon6 from "../assets/images/category-icon-06.svg";
import categoryIcon7 from "../assets/images/category-icon-07.svg";
import categoryIcon8 from "../assets/images/category-icon-08.svg";
import categoryIcon9 from "../assets/images/category-icon-09.svg";
import categoryIcon10 from "../assets/images/category-icon-10.svg";
import categoryIcon12 from "../assets/images/category-icon-12.svg";

const Category = () => {
    useEffect(()=>{
      window.scrollTo(0,0);
    },[])
    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Category</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Category
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="category-sec tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon} alt="category-icon" />
                                    <h5>Biscuits &amp; Snacks</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon2} alt="category-icon2" />
                                    <h5>Petti</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon3} alt="category-icon3" />
                                    <h5>Household Care</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon4} alt="category-icon4" />
                                    <h5>Pickle</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon5} alt="category-icon5" />
                                    <h5>Honey</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon6} alt="category-icon6" />
                                    <h5>Masala</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon7} alt="category-icon7" />
                                    <h5>Breakfast &amp; Dairy</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon8} alt="category-icon8" />
                                    <h5>Grocery &amp; Staples</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon9} alt="category-icon9" />
                                    <h5>Fruits &amp; Vegetables</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon10} alt="category-icon10" />
                                    <h5>Beverages</h5>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                            <Link to="/shop">
                                <div className="category-card">
                                    <img src={categoryIcon12} alt="category-icon12" />
                                    <h5> Meats &amp; Seafood</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Category