import axios from 'axios';
import React, { useEffect, useState } from 'react'
import RangeSlider from "react-range-slider-input";
import baseUrl from './Helper/BaseUrl';
import { useDispatch } from 'react-redux';
import { filterProductsApi } from './features/productSlice';
const FilterSection = () => {
    const dispatch = useDispatch();
    const [catList, setCatList] = useState([])
    const [subCatList, setSubCatList] = useState([])
    const [priceRange, setPriceRange] = useState([0, 2500])
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');

    const handleRangeChange = (values) => {
        // console.log("range", values)
        setPriceRange(values)
    }
    const fetchCategories = async () => {
        try {
            const res = await axios.get(`${baseUrl}/category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            // console.log(res)
            setCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error.response)
        }
    }

    const fetchSubCategories = async () => {
        try {
            const res = await axios.get(`${baseUrl}/sub-category-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            // console.log(res)
            setSubCatList(res.data.data)
        } catch (error) {
            console.log("fetch cat error", error.response)
        }
    }

    const handleCategoryChange = (catId) => {
        // console.log("catId", catId)
        setSelectedCategoryId(selectedCategoryId === catId ? '' : catId);
    };

    const handleSubCategoryChange = (subCatId) => {
        // console.log("subCatId", subCatId)
        setSelectedSubCategoryId(selectedSubCategoryId === subCatId ? '' : subCatId);
    };

    const handleApplyFilter = () => {
        const filters = {
            category: selectedCategoryId,
            sub_category: selectedSubCategoryId,
            price: priceRange[1]
        };
        dispatch(filterProductsApi(filters));
    };
    
    const handleClearAll = () => {

        setSelectedCategoryId('');
        setSelectedSubCategoryId('');
        setPriceRange([0, 2500]);
        const filters = {
            category: '',
            sub_category: '',
            price: ''
        };
        dispatch(filterProductsApi(filters));
    };
    useEffect(() => {
        fetchSubCategories();
        fetchCategories();
    }, [])
    return (
        <>
            <div className="col-lg-3 col-md-4">
                <div className="shop-bar sticky-lg-top">
                    <div className="shop-bar-head">
                        <h5>Filter</h5>
                        <button type='button bg-none bg-0 ' onClick={handleClearAll}>Clear All</button>
                    </div>
                    <div className="shop-bar-innr">
                        <div className="accordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#filter-01"
                                    >
                                        Category
                                    </button>
                                </h2>
                                <div
                                    id="filter-01"
                                    className="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        {
                                            catList.map((cat, index) => {
                                                return (
                                                    cat.status && (
                                                        <div key={cat._id} className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                defaultValue=""
                                                                id={`category-${cat._id}`}
                                                                checked={selectedCategoryId === cat._id}
                                                                onChange={() => handleCategoryChange(cat._id)}
                                                                
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`category-${cat._id}`}
                                                            >
                                                                {" "}
                                                                {cat.name}
                                                            </label>
                                                        </div>
                                                    )
                                                )

                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#filter-02"
                                    >
                                        Sub Category
                                    </button>
                                </h2>
                                <div id="filter-02" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        {
                                            subCatList.map((sub) => {
                                                return (
                                                    sub.status && (
                                                        <div key={sub._id} className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={selectedSubCategoryId === sub._id}
                                                                onChange={() => handleSubCategoryChange(sub._id)}
                                                                id={`sub-category-${sub._id}`}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`sub-category-${sub._id}`}
                                                            >
                                                                {sub.name}
                                                            </label>
                                                        </div>
                                                    )
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#filter-03"
                                    >
                                        Price
                                    </button>
                                </h2>
                                <div id="filter-03" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className="range-slide">
                                            <RangeSlider
                                                className="single-thumb w-100"
                                                defaultValue={[0, 2500]}
                                                thumbsDisabled={[true, false]}
                                                rangeSlideDisabled={true}
                                                name="points2"
                                                value={priceRange}
                                                onInput={handleRangeChange}
                                                min={0}
                                                max={2500}
                                            />
                                            <div className="slider-labels d-flex justify-content-between pt-3">
                                                <span>{priceRange[0]}</span>
                                                <span>{priceRange[1]}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type='button'
                                    onClick={handleApplyFilter}
                                    className="thm-btn dark p-3 w-100"
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterSection