import React, { useEffect } from 'react'
import check from "../assets/images/check.svg";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWislistItem, fetchWishlist, } from './features/wishlistSlice';
import { toast } from 'react-toastify';
import { imgUrl } from './Helper/BaseUrl';
const Wishlist = () => {
    const dispatch = useDispatch();
    const wistlistitem = useSelector(state => state?.wishlist?.wishlistItems?.products || [])
    // console.log("12121",wistlistitem)
 
    const handleRemoveFromWishlist = (productId) => {
        dispatch(deleteWislistItem(productId))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
            dispatch(fetchWishlist());
    }, [dispatch]);

    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>My Wishlist</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        My Wishlist
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-sec product-page tp-space">
                <div className="container">
                    {wistlistitem?.length === 0 ? (
                        <>
                            <div className='text-center'>
                                <h1>Your wishlist is empty.</h1>
                                <Link to='/shop' style={{borderRadius:"50px"}} className='thm-btn mt-2'>Continue Shopping</Link>
                            </div>
                        </>
                    ) : (
                        <div className="row">
                                {wistlistitem && wistlistitem.map((product) => (
                                <div key={product._id} className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                                <img src={`${imgUrl}/${product?.product_id?.product_images[0]?.image}`} alt={product?.product_id?.product_name} />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                {
                                                    product?.product_id?.authentic && (
                                                        <span><img
                                                            src={check}
                                                            className="me-1"
                                                            alt="check"
                                                        /> Authentic</span>
                                                    )
                                                }
                                                {
                                                    product?.product_id?.in_stock ? (
                                                        <span className="text-success">  <i className="fa fa-check me-1" /> In stock</span>

                                                    ) : (
                                                        <span className="text-success">out of stock</span>

                                                    )
                                                }
                                            </p>
                                            <h5>
                                                <Link to={`/productdetails/${product?.product_id?._id}`}>{product?.product_id?.product_name}</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">{product?.product_id?.price} <del>{product?.product_id?.discount_price} </del></p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <button 
                                                onClick={() => handleRemoveFromWishlist(product?.product_id?._id)} 
                                                className="fav-btn">
                                                    <i className="fas fa-heart" />
                                                </button>
                                                <button
                                                    className="thm-btn"
                                                >
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           ))}
                            {/* <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product2} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product3} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product4} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product5} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product6} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            {/* ********** */}
                            {/* <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product7} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product8} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product9} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product10} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product11} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product-card">
                                <div className="product-img">
                                    <img src={product12} alt="product" />
                                </div>
                                <div className="product-content">
                                    <p className="auth">
                                        <span className='d-flex'>
                                            <img src={check} className="me-1" alt="check" />
                                            Authentic{" "}
                                        </span>
                                        <span className="text-success">
                                            <i className="fa fa-check me-1" />
                                            In stock
                                        </span>
                                    </p>
                                    <h5>
                                        <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                    </h5>
                                    <p className="rating">
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fas fa-star" />
                                        <span className="fa-regular fa-star" />
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <a href="javascript:void(0);" className="fav-btn">
                                            <i className="fa-regular fa-heart" />
                                        </a>
                                        <a href="javascript:void(0);" className="thm-btn">
                                            <i className="fas fa-shopping-basket me-1" />
                                            Add to Cart
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                  )} 
                </div>
            </section>
        </>

    )
}

export default Wishlist