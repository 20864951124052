import React, { useState } from 'react'
import registration from "../../assets/images/registration-img.png";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
const NewPasswordModal = () => {
    const [values, setValues] = useState({
        mobile: "",
        otp: "",
        password: "",
        confirm_password: "",
    });
    const [loading,setLoading] = useState(false)
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }

    }
    const validateNewPassword = () => {
        const { mobile, otp, password, confirm_password, } = values;
        if (!mobile?.trim()) {
            toast.error('Please Enter Phone Number');
            return false;
        }
        if (!/^\d{10}$/.test(mobile)) {
            toast.error('Phone Number must be exactly 10 digits.');
            return false;
        }
        if (!otp?.trim()) {
            toast.error('Please Enter Otp');
            return false;
        }
        if (!password) {
            toast.error('Password Field Required');
            return false;
        }
        if (password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            return false;
        }
        if (!confirm_password) {
            toast.error('Confirm Password Field Required');
            return false;
        }
        if (password !== confirm_password) {
            toast.error('Confirm Password Not Matched');
            return false;
        }
        return true;
    }
    // console.log("Form data:", values);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateNewPassword()) {
            // console.log("Form data:", values);
            // values.otp=1234
            setLoading(true)
            try {
                const res = await axios.post(`${baseUrl}/forget-password`,  values , {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                // console.log(res)
                if (res.data.status) {
                    const forgotModalEl = document.getElementById('new-password');
                    const forgotInstance = window.bootstrap.Modal.getInstance(forgotModalEl)
                    forgotInstance.hide();

                    const otpModalEl = document.querySelector('#login');
                    const otpModal = new window.bootstrap.Modal(otpModalEl);
                    otpModal.show();
                    toast.success(res.data.message)
                    setValues({
                        mobile: "",
                        otp: "",
                        password: "",
                        confirm_password: "",
                    });
                    setLoading(false)
                } else {
                      setLoading(false)
                    // console.log(res.data.message)
                    toast.error(res.data.message)
                }
            } catch (error) {
                setLoading(false)
                console.log("new password error", error)
            }
        }
    }
    return (
        <>
            <div
                className="modal fade registration"
                id="new-password"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Forgot Password</h3>
                                            <p className="text-white">
                                                Please provide your registered Mobile number. Get started
                                                into
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Mobile Number</label>
                                                <input
                                                    type="tel"
                                                    name='mobile'
                                                    value={values.mobile}
                                                    onChange={handleChange}
                                                    className="form-control mobile"
                                                    placeholder="Enter Number"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Enter OTP</label>
                                                <input
                                                    type="number"
                                                    name='otp'
                                                    value={values.otp}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter OTP"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">New Password</label>
                                                <input
                                                    type="password"
                                                    name='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter New Password"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Confirm New Password</label>
                                                <input
                                                    type="password"
                                                    name='confirm_password'
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Confirm New Password"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Save"
                                                    )}
                                                    {/* Save */}
                                                </button>
                                            </div>
                                            <p className="clr fz-18 text-center mt-4">
                                                Existing Members?{" "}
                                                <a
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#login"
                                                    className="txt-org fw-600"
                                                >
                                                    Sign In
                                                </a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewPasswordModal