import React, { useState } from 'react'
import registration from "../../assets/images/registration-img.png";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
const RegisterModal = ({ setmobileno }) => {
    const [values, setValues] = useState({
        mobile: "",
        full_name: "",
        email: "",
        password: "",
        confirm_password: "",
    });
    const [loading, setLoading] = useState(false)
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }

    }
    const validateRegister = () => {
        const { mobile, full_name, email, password, confirm_password, } = values;
        if (!mobile?.trim()) {
            toast.error('Please Enter Phone Number');
            return false;
        }
        if (!/^\d{10}$/.test(mobile)) {
            toast.error('Phone Number must be exactly 10 digits.');
            return false;
        }
        if (!full_name?.trim()) {
            toast.error('Please Enter Your Name');
            return false;
        }
        if (!email?.trim()) {
            toast.error('Please Enter Your Email');
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please Enter Valid Email Address');
            return false;
        }
        if (!password) {
            toast.error('Password Field Required');
            return false;
        }
        if (password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            return false;
        }
        if (!confirm_password) {
            toast.error('Confirm Password Field Required');
            return false;
        }
        if (password !== confirm_password) {
            toast.error('Confirm Password Not Matched');
            return false;
        }
        return true;
    }
    // console.log(values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateRegister()) {
            setLoading(true)
            try {
                const response = await axios.post(`${baseUrl}/register`, values)
                // console.log("object", response)
                if (response.data.status) {
                    //    toast.success(response.data.message)
                    toast.success('OTP Send Successfully,Please Verify OTP')
                    // Get both modals
                    const registerModalEl = document.getElementById('register');
                    const registerInstance = window.bootstrap.Modal.getInstance(registerModalEl)
                    registerInstance.hide();

                    const otpModalEl = document.querySelector('#verify-otp');
                    setmobileno(values.mobile)
                    const otpModal = new window.bootstrap.Modal(otpModalEl);
                    otpModal.show();

                    // console.log("Form data:", values);
                    setValues({
                        mobile: "",
                        full_name: "",
                        email: "",
                        password: "",
                        confirm_password: "",
                    });
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message)
                }
            } catch (error) {
                setLoading(false)
                console.log("register error", error)
            }


        }
    }
    return (
        <>
            <div
                className="modal fade registration"
                id="register"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Register</h3>
                                            <p className="text-white">
                                                Enter your credentials to log into your account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Mobile Number</label>
                                                <input
                                                    type="tel"
                                                    name='mobile'
                                                    value={values.mobile}
                                                    onChange={handleChange}
                                                    className="form-control mobile"
                                                    placeholder="Enter Number"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Full Name</label>
                                                <input
                                                    type="text"
                                                    name='full_name'
                                                    value={values.full_name}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Full Name"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Email Address</label>
                                                <input
                                                    type="email"
                                                    name='email'
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Password</label>
                                                <input
                                                    type="password"
                                                    name='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Confirm Password</label>
                                                <input
                                                    type="password"
                                                    name='confirm_password'
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Confirm Password"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#verify-otp"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Get OTP"
                                                    )}
                                                    
                                                </button>
                                            </div>
                                            <p className="clr fz-18 text-center mt-4">
                                                Existing Members?{" "}
                                                <a
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#login"
                                                    className="txt-org fw-600"
                                                >
                                                    Sign In
                                                </a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterModal