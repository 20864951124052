import React, { useEffect, useMemo } from 'react';
import sale from "../assets/images/sale-img.png";
import sale2 from "../assets/images/sale-img-02.png";
import advertisement from "../assets/images/advertisement-img-01.png";
import advertisement2 from "../assets/images/advertisement-img-02.png";
import OwlSlider from './Sliders/OwlSlider'
import NewArrivalSlider from './Sliders/NewArrivalSlider';
import CorporateGiftingSlider from './Sliders/CorporateGiftingSlider';
import BestSellersSlider from './Sliders/BestSellersSlider';
import PopularProductSlider from './Sliders/PopularProductSlider';
import BiscuitsSnacksSlider from './Sliders/BiscuitsSnacksSlider';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from './features/productSlice';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: true
            },
            768: {
                items: 3,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 5,
                nav: true
            }
        }
    }
    const options2 = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: true
            },
            768: {
                items: 3,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 5,
                nav: true
            }
        }
    }
    const options3 = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            576: {
                items: 2,
                nav: true
            },
            768: {
                items: 3,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 5,
                nav: true
            }
        }
    }
    const dispatch = useDispatch()
    const { bestSellerProducts, arrivalProducts, popularProducts } = useSelector(state => state.product);
    const user = useSelector((state) => state.auth.user);
    // Memoize the product arrays with proper mapping
    const memoizedArrivals = useMemo(() =>
        arrivalProducts?.map(product => ({
            ...product,
            key: product._id
        })) || [],
        [arrivalProducts]);

    const memoizedBestSellers = useMemo(() =>
        bestSellerProducts?.map(product => ({
            ...product,
            key: product._id
        })) || [],
        [bestSellerProducts]);

    const memoizedPopular = useMemo(() =>
        popularProducts?.map(product => ({
            ...product,
            key: product._id
        })) || [],
        [popularProducts]);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchProducts());
    }, [dispatch]);
    // Memoize the data
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchProducts());
    }, [dispatch, user]);
    if (!arrivalProducts) {
        return
    }
    if (!bestSellerProducts) {
        return
    }
    if (!popularProducts) {
        return
    }
    return (
        <>
            <OwlSlider />
            {/* new arrival section start*/}
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            New <span>Arrivals</span>
                        </h3>
                        <Link to="/view-all" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options}>
                        {memoizedArrivals && memoizedArrivals.map(product => (
                            <NewArrivalSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/*  */}
            {/* new arrival section end*/}

            {/* Corporate Gifting section start */}
            <CorporateGiftingSlider />
            {/* Corporate Gifting section end */}

            {/* Popular Products section start */}
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Popular <span>Products</span>
                        </h3>
                        <Link to="/view-all" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options2}>
                        {memoizedPopular && memoizedPopular.map(product => (
                            <PopularProductSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/* Popular Products section end */}


            <section className="sale-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <img src={sale} alt="sale" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <div className="sale-content text-center">
                                <h3>Seasonal Clearance Sale</h3>
                                <h6>Save up to 40%</h6>
                                <Link to="/shop" className="thm-btn">
                                    Shop Now
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <img src={sale2} alt="sale2" />
                        </div>
                    </div>
                </div>
            </section>

            {/*  Bestsellers section start */}
            <section className="product-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Best <span>Bestsellers</span>
                        </h3>
                        <Link to="/view-all" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="product-slide owl-theme owl-carousel" {...options3}>
                        {memoizedBestSellers && memoizedBestSellers.map(product => (
                            <BestSellersSlider key={product._id} product={product} user={user} />
                        ))}
                    </OwlCarousel>
                </div>
            </section>
            {/* Bestsellers section end */}

            {/* new arrival section start*/}
            {/* <NewArrivalSlider sliderData={memoizedData.sliderData} /> */}
            {/* new arrival section end*/}

            <section className="advertisement-sec tp-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="advertisement-card">
                                <div>
                                    <img src={advertisement} alt="advertisement" />
                                </div>
                                <div className="advertisement-content">
                                    <h4>Tasty Snack &amp; Fast food</h4>
                                    <p>The flavour of something special</p>
                                    <Link to="/shop" className="thm-btn org">
                                        Shop Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="advertisement-card grn-bg">
                                <div>
                                    <img src={advertisement2} alt="advertisement2" />
                                </div>
                                <div className="advertisement-content">
                                    <h4>Fresh Fruits &amp; Vegetables</h4>
                                    <p>A healthy meal for every one</p>
                                    <Link to="/shop" className="thm-btn org">
                                        Shop Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Biscuits Snacks section start*/}
            {/* <BiscuitsSnacksSlider sliderData={memoizedData.sliderData} /> */}
            {/* Biscuits Snacks section end*/}
        </>

    )
}

export default Home