import React, { useEffect, useState } from 'react'
import about from "../assets/images/about-img.png";
import about2 from '../assets/images/about-img-02.png';
import about3 from '../assets/images/about-img-03.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseUrl from './Helper/BaseUrl';
const AboutUs = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    useEffect(() => {
        fetchSingleCms();
    }, [])

    // // fetch sub category
    const fetchSingleCms = async () => {

        try {
            const res = await axios.get(`${baseUrl}/cms/view/6752952f3e2d1fce8fe1a0b0`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("about", res.data.data)
            setTitle(res.data.data.title)
            setDescription(res.data.data.description)
        } catch (error) {
            console.log("fetch cms error", error)
        }
    }
    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>About Us</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        About Us
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content-sec tp-space pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content-abt">
                                {/* <img src={about} alt="about" /> */}
                                <h4 className="fz-22 fw-600 text-dark">{title}</h4>
                                {/* <p>
                                    We may automatically track certain information about you based
                                    upon your behavior on the website. We use this information to do
                                    internal research on our users’ demographics, interests, and
                                    behavior to better understand, protect and serve our users. This
                                    information is compiled and analyzed on an aggregated basis. This
                                    information may include the URL that you just came from (whether
                                    this URL is on the website or not), which URL you next go to
                                    (whether this URL is on the website or not), your computer browser
                                    information, your IP address, and other information associated
                                    with your interaction with the website.&nbsp;We may also share
                                    your Mobile IP/Device IP with third party(ies) and to the best of
                                    our knowledge, be-life and representations given to us by these
                                    third party(ies) this information is not stored.
                                </p>
                                <p>
                                    Should you have any clarifications regarding this Privacy Policy,
                                    please do not hesitate to contact us at{" "}
                                    <a href="mailto:info@borobazar.com" className="txt-org">
                                        info@borobazar.com
                                    </a>
                                </p> */}
                                
                            </div>
                            <div
                                className="about-content"
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        </div>

                        {/* <div className="col-lg-6">
                            <img
                                src={about2}
                                className="w-100 mt-2 mb-2"
                                alt="about2"
                            />
                        </div>
                        <div className="col-lg-6">
                            <img
                                src={about3}
                                className="w-100 mt-2 mb-2"
                                alt="about3"
                            />
                        </div>
                        <div className="col-lg-12">
                            <p>
                                We may automatically track certain information about you based upon
                                your behavior on the website. We use this information to do internal
                                research on our users’ demographics, interests, and behavior to
                                better understand, protect and serve our users. This information is
                                compiled and analyzed on an aggregated basis. This information may
                                include the URL that you just came from (whether this URL is on the
                                website or not), which URL you next go to (whether this URL is on
                                the website or not), your computer browser information, your IP
                                address, and other information associated with your interaction with
                                the website.&nbsp;We may also share your Mobile IP/Device IP with
                                third party(ies) and to the best of our knowledge, be-life and
                                representations given to us by these third party(ies) this
                                information is not stored.
                            </p>
                            <p>
                                We may automatically track certain information about you based upon
                                your behavior on the website. We use this information to do internal
                                research on our users’ demographics, interests, and behavior to
                                better understand, protect and serve our users. This information is
                                compiled and analyzed on an aggregated basis. This information may
                                include the URL that you just came from (whether this URL is on the
                                website or not), which URL you next go to (whether this URL is on
                                the website or not), your computer browser information, your IP
                                address, and other information associated with your interaction with
                                the website.&nbsp;We may also share your Mobile IP/Device IP with
                                third party(ies) and to the best of our knowledge, be-life and
                                representations given to us by these third party(ies) this
                                information is not stored.
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>
        </>

    )
}

export default AboutUs