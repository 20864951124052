import React, { useEffect, useState } from 'react'
import corparate from "../assets/images/corparate-img-01.png"
import corparate2 from "../assets/images/corparate-img-02.png";
import corparate3 from "../assets/images/corparate-img-03.png";
import corparate4 from "../assets/images/corparate-img-04.png";
import corparate5 from "../assets/images/corparate-img-05.png"
import corparate6 from "../assets/images/corparate-img-06.png";
import corparate7 from "../assets/images/corparate-img-07.png";
import corparate8 from "../assets/images/corparate-img-08.png";
import gift from "../assets/images/gift-img-01.png";
import gift2 from "../assets/images/gift-img-02.png";
import gift3 from "../assets/images/gift-img-03.png"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import bootstrap from 'bootstrap';
const CorporateGifting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const options = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            350: {
                items: 1.5,
                nav: true
            },
            576: {
                items: 1.5,
                nav: true
            },
            768: {
                items: 2,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 4,
                nav: true
            }
        }
    };
    const options1 = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        items: 4,
        responsive: {
            0: {
                items: 1,
                nav: true,
            },
            350: {
                items: 1.5,
                nav: true,
            },
            576: {
                items: 1.5,
                nav: true
            },
            768: {
                items: 2,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 4,
                nav: true
            }
        }
    };

    const [values, setValues] = useState({
        name: "",
        company_name: "",
        phone_number: "",
        email: "",
        location: "",
        boxes: "",
        comments: "",
    })
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone_number') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }
    const validateGift = () => {
        const { name, company_name, phone_number, email, location, boxes, comments } = values;
        if (!name.trim()) {
            toast.error("Please Enter Name")
            return false;
        }
        if (!company_name.trim()) {
            toast.error("Please Enter Company Name")
            return false;
        }
        if (!phone_number.trim()) {
            toast.error('Please Enter your Phone Number')
            return false;
        }
        if (!/^\d{10}$/.test(phone_number)) {
            toast.error('Phone Number must be exactly 10 digits.');
            return false;
        }
        if (!email?.trim()) {
            toast.error('Please Enter Your Email');
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please Enter Valid Email Address');
            return false;
        }
        if (!location.trim()) {
            toast.error("Please Enter Location")
            return false;
        }
        if (!boxes.trim()) {
            toast.error("Please Enter Number of Boxes")
            return false;
        }
        if (!comments.trim()) {
            toast.error("Please Enter Comment")
            return false;
        }
        return true;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateGift()) {
            console.log('gifts', values)
            // Close the modal
            const modal = document.getElementById('custom-quote');
            const modalInstance = window.bootstrap.Modal.getInstance(modal);
            modalInstance.hide();

            // Optional: Reset form values
            setValues({
                name: "",
                company_name: "",
                phone_number: "",
                email: "",
                location: "",
                boxes: "",
                comments: "",
            });
        }

    }
    return (
        <>
            <section className="gifting-bnnr">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="gifting-content">
                                <h1>Corporate Celebration Crafters</h1>
                                <p>
                                    Perfect for teams of all sizes, We cater to both small and large
                                    corporate gifting with orders from 500 to 30,000.
                                </p>
                                <a href="javascript:void(0);" className="thm-btn dark">
                                    <i className="fab fa-whatsapp me-2" />
                                    WhatsApp Now
                                 </a>
                                <a
                                    href="javascript:void(0);"
                                    className="thm-btn white-btn ms-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#custom-quote"
                                >
                                    Customize your Gift Now
                                 </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="corparate-sec tp-space">
                <div className="container">
                    <div className="heading pe-0">
                        <h3 className="title">
                            Corporate <span>Gifting</span>
                        </h3>
                    </div>
                    <OwlCarousel className="corporate-slide owl-theme owl-carousel" {...options}>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate} alt="corparate" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/productdetails">
                                <img src={corparate2} alt="corparate2" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate3} alt="corparate3" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate4} alt="corparate4" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate2} alt="corparate2" />
                             </Link>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            <section className="corparate-sec tp-space">
                <div className="container">
                    <div className="heading pe-0">
                        <h3 className="title">
                            Customized Hampers &amp; <span>Personalization</span>
                        </h3>
                    </div>
                    <OwlCarousel className="corporate-slide owl-theme owl-carousel" {...options1}>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate5} alt="corparate5" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate6} alt="corparate6" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate7} alt="corparate7" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate8} alt="corparate8" />
                             </Link>
                        </div>
                        <div className="corparate-img">
                           <Link to="/productdetails">
                                <img src={corparate2} alt="corparate2" />
                             </Link>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            <section className="corparate-gift tp-space">
                <div className="container">
                    <h3 className="title text-center mb-4">
                        Why Choose website Name for Corporate Gifting?
                    </h3>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                   <Link to="/productdetails">
                                        <div className="corparate-gift-card">
                                            <img src={gift} alt="gift" />
                                            <h5>Diverse Budget Selections</h5>
                                            <p>
                                                Our flexible budget options, ranging from ₹350 to ₹40,000.
                                            </p>
                                        </div>
                                     </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                   <Link to="/productdetails">
                                        <div className="corparate-gift-card">
                                            <img src={gift2} alt="gift2" />
                                            <h5>Personalized Branding</h5>
                                            <p>
                                                From custom logos to localized messaging, we tailor your
                                                brand to each audience.
                                            </p>
                                        </div>
                                     </Link>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                   <Link to="/productdetails">
                                        <div className="corparate-gift-card">
                                            <img src={gift3} alt="gift3" />
                                            <h5>Flexible Delivery Options</h5>
                                            <p>
                                                We ship to single or multiple locations, ensuring smooth
                                                deliveries.
                                            </p>
                                        </div>
                                     </Link>
                                </div>
                                <div className="col-lg-12 text-center mt-5">
                                    <a
                                        href="javascript:void(0);"
                                        data-bs-toggle="modal"
                                        data-bs-target="#custom-quote"
                                        className="thm-btn dark"
                                    >
                                        Customize your Gift Now
                                     </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* custom-request-start */}
            <div
                className="modal fade registration"
                id="custom-quote"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fz-20 fw-500" id="exampleModalLabel">
                                Request a Custom Quote
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="registration-frm">
                                <form action="" onSubmit={handleSubmit}>
                                    <div className="registration-bx">
                                        <label htmlFor="">Name</label>
                                        <input
                                            type="text"
                                            name='name'
                                            value={values.name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Company Name</label>
                                        <input
                                            type="text"
                                            name='company_name'
                                            value={values.company_name}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Mobile Number</label>
                                        <input
                                            type="tel"
                                            name='phone_number'
                                            value={values.phone_number}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Email Address</label>
                                        <input
                                            type="email"
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Location</label>
                                        <input
                                            type="text"
                                            name='location'
                                            value={values.location}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Number of Boxes Required</label>
                                        <input
                                            type="number"
                                            name='boxes'
                                            value={values.boxes}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder=""
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <label htmlFor="">Comments</label>
                                        <textarea
                                            name='comments'
                                            value={values.comments}
                                            onChange={handleChange}
                                            className="form-control height-100"
                                        // defaultValue={""}
                                        />
                                    </div>
                                    <div className="registration-bx">
                                        <button
                                            className="thm-btn dark w-100 rounded-5 p-3"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* custom-request-end */}


        </>

    )
}

export default CorporateGifting