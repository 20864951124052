import React from 'react'
import { useSelector } from 'react-redux';

const PriceDisplay = ({ priceUSD }) => {
    const { rates, selectedCurrency } = useSelector((state) => state.currency);

    const convertPrice = (price) => {
        const rate = rates[selectedCurrency];
        return (price * rate).toFixed(2);
    };
    return (
        <>
            <div>
                {selectedCurrency} {convertPrice(priceUSD)}
            </div>
        </>
    )
}

export default PriceDisplay