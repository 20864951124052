import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import baseUrl, { imgUrl } from '../Helper/BaseUrl';
import axios from 'axios';
const OwlSlider = () => {
    const [owlData, setOwlData] = useState([])
    const [loading_home, setLoading_home] = useState(true)
    const options = {
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        autoplay: true,
        responsive: {
            0: { items: 1 },
            600: { items: 1 },
            1000: { items: 1 }
        }
    }; 

    const fetchOwlData = async () => {
        setLoading_home(true)
        try {
            const res = await axios.get(`${baseUrl}/hero-section-slider`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            setOwlData(res.data.data)
            setLoading_home(false)

        } catch (error) {
            console.log("error fetch owl data", error)
        }
    }

    useEffect(() => {
        fetchOwlData();
    }, [])

    if (loading_home){
        return 
    }
    return (
        <>
            <section className="hero-sec mt-150">
                <div className="container">
                    <div className="hero-sec-innr">
                        <OwlCarousel className="banner-slide owl-carousel owl-theme" {...options}>
                            {/* {owlData.map((owl, index) => renderSlide(owl, index))} */}
                            {/* {memoizedOwlData} */}
                            {
                                owlData.map((owl,index) => {
                                    return (
                                       <Link key={index} to='/shop'>
                                            <div key={index} className="item banner-main-img">
                                                <img src={`${imgUrl}/${owl.image}`} alt="banner" />
                                                {/* <div className="hero-sec-innr-content text-center">
                                                <h5>{owl.main_heading}</h5>
                                                <h2>{owl.sub_heading}</h2>
                                                <h5>{owl.lowerTitle}</h5>
                                                <Link to="/shop">{owl.button_heading}</Link>
                                            </div> */}
                                            </div>
                                       </Link>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </section >

        </>
    )
}

export default OwlSlider;