import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png";
import cancelIcon from "../assets/images/cancel-icon.png";
import successIcon from "../assets/images/success-icon.gif";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
const OrderDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [formData, setFormData] = useState({
        reason: '',
        details: '',
        payment: 'credit-card'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateCancelOrder = () => {
        const { reason, details } = formData;
        if (!reason.trim()) {
            toast.error('Please Enter a valid reason')
            return false
        }
        if (!details.trim()) {
            toast.error('Please Enter a details')
            return false;
        }
        return true;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateCancelOrder()) {
            const registerModalEl = document.getElementById('cancel-order');
            const registerInstance = window.bootstrap.Modal.getInstance(registerModalEl)
            registerInstance.hide();

            const otpModalEl = document.querySelector('#cancel-request');
            const otpModal = new window.bootstrap.Modal(otpModalEl);
            otpModal.show();
            console.log('Form submitted:', formData);
            setFormData({
                reason:"",
                details:"",
                payment:"credit-card"
            })
        }

    };
    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Order Details</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Order Details
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="order-details-page tp-space pt-0">
                <div className="container">
                    <div className="order-card ">
                        <div className="order-card-head">
                            <p className="mb-0">
                                <span className="badges">Order: #883475</span> Order Placed: 03 Jun
                                2020
                            </p>
                            <p className="mb-0">
                                Payment Mode: <span className="text-success">Online</span>
                            </p>
                        </div>
                        <div className="order-card-mddle">
                            <div className="order-card-bx">
                                <img src={thumb} alt="thumb" />
                                <div className="order-card-bx-content">
                                    <h6>Ground Nuts Oil Snack</h6>
                                    <p>
                                        50gm <br /> QTY.: 1
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0 text-success fw-600">
                                <em className="fas fa-circle me-1" />
                                Shipped on Jan 21
                            </p>
                            <p className="mb-0">
                                Delivery Expected By:{" "}
                                <span className="d-block text-dark">24 Jun 2024</span>
                            </p>
                        </div>
                        <div className="order-card-bttm">
                            <p className="mb-0">
                                Order Placed: 03 Jun 2020 | Shipped on Jan 21, 2020 | 10:30 AM
                            </p>
                            <div>
                                <a
                                    href="javascript:void(0);"
                                    className="thm-btn danger"
                                    data-bs-toggle="modal"
                                    data-bs-target="#cancel-order"
                                >
                                    Cancel Order{" "}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="checkout-add-innr">
                                <div className="select-add">
                                    <h5 className="fz-20 fw-600">Address 1</h5>
                                </div>
                                <div className="p-3">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Billing Details</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                            <p>
                                                Elevated Ajmer Rd, Jai Ambey Colony Civil Lines, First Floor
                                                Jaipur, Rajasthan(302028), India{" "}
                                                <a href="javascript:void(0);" className="clr d-block">
                                                    +91-9876543210
                                                </a>
                                            </p>
                                            <a href="javascript:void(0);" className="txt-org">
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bill-summary-main">
                                <h5>Bill Summary</h5>
                                <ul className="bill-summary">
                                    <li>
                                        <span>Subtotal</span>
                                        <span>$57.70</span>
                                    </li>
                                    <li>
                                        <span>Shipping</span>
                                        <span>$0</span>
                                    </li>
                                    <li>
                                        <span>Total</span>
                                        <span className="txt-org">$57.70</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="order-traking">
                                <h6>Order Tracking</h6>
                                <div className="p-3">
                                    <ul className="delivery-track">
                                        <li className="deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Ordered</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                        <li className="deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Packed</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                        <li className="not-deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Shipped</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                        <li className="not-deliver">
                                            <em className="fas fa-map-marker-alt" />
                                            <h5>Delivered</h5>
                                            <p>Jan 21, 2020, Sun</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* cancel-order-start */}
            <div
                className="modal fade cancel-modal"
                id="cancel-order"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Cancel Order
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={cancelIcon}
                                    className="img-90"
                                    alt="cancelIcon"
                                />
                            </div>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Reason for Cancellation</label>
                                    <select name="reason"
                                        value={formData.reason}
                                        onChange={handleChange} id="" className="form-select">
                                        <option value="">My Reason is not listed</option>
                                        <option value="reason1">Reason1</option>
                                        <option value="reson2">Reason2</option>
                                    </select>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Write Details</label>
                                    <textarea
                                        name="details"
                                        value={formData.details}
                                        onChange={handleChange}
                                        className="form-control height-100"
                                    // defaultValue={""}
                                    />
                                </div>
                                <div className="payment">
                                    <h5 className="fz-16 fw-600 mb-3">Choose a Refund mode</h5>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="pay-01"
                                            name="payment"
                                            value="credit-card"
                                            checked={formData.payment === 'credit-card'}
                                            onChange={handleChange}
                                        // defaultChecked=""
                                        />
                                        <label className="form-check-label" htmlFor="pay-01">
                                            {" "}
                                            Debit/ Credit Card{" "}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="gpay"
                                            checked={formData.payment === 'gpay'}
                                            onChange={handleChange}
                                            id="pay-02"
                                        />
                                        <label className="form-check-label" htmlFor="pay-02">
                                            G PAY
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="phonepe"
                                            checked={formData.payment === 'phonepe'}
                                            onChange={handleChange}
                                            id="pay-03"
                                        />
                                        <label className="form-check-label" htmlFor="pay-03">
                                            Phone Pe
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="twint"
                                            checked={formData.payment === 'twint'}
                                            onChange={handleChange}
                                            id="pay-04"
                                        />
                                        <label className="form-check-label" htmlFor="pay-04">
                                            Twint
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="thm-btn w-100 text-center danger"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#cancel-request"
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* cancel-order-end */}
            {/* order-cancel-success-start */}
            <div
                className="modal fade cancel-modal"
                id="cancel-request"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={successIcon} alt="successicon" />
                            <p className="text-center text-success fz-20 fw-600 mb-3">
                                Cancel Request submitted Successfully
                            </p>
                            <a
                                href="javascript:void(0);"
                                className="thm-btn"
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-cancel-success-end */}

        </>

    )
}

export default OrderDetails