import { configureStore } from "@reduxjs/toolkit";
import authReducer from './features/authSlice'
import productReducer from './features/productSlice'
import cartReducer from './features/cartSlice'
import wishlistReducer from './features/wishlistSlice'
import currencyReducer from './features/currencySlice'

const store = configureStore({
    reducer:{
        auth:authReducer,
        cart:cartReducer,
        product:productReducer,
        wishlist:wishlistReducer,
        currency: currencyReducer,
    },  
     middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export default store;
