import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import successcart from "../assets/images/success-cart.svg";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Address from './Address';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
import axios from 'axios';
import { deleteCartItemApi, updateCartQuantity, updateCartQuantityAPI } from './features/cartSlice';
const Checkout = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("token")
    const { items, loading, error, cartId } = useSelector(state => state.cart);

    const user = useSelector((state) => state.auth.user)
    // console.log(user._id)
    const userId = user._id;
    // console.log("checkout", items)
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0)
    const [selectedAddressId, setSelectedAddressId] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('COD');
    const [orderId, setOrderId] = useState(null);
    // console.log("selected address",selectedAddressId)
    // verify coupon api
    const verifyCoupon = async () => {
        if (!couponCode) {
            toast.error('Please enter a coupon code');
            return;
        }
        try {
            const response = await axios.post(`${baseUrl}/coupon-verify`,
                { coupon_code: couponCode },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            if (!response.data.status) {
                toast.success(response.data.message);
                setDiscount(response.data.data.discount)
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error.message)
            console.log("error coupon apply", error)
        }
    }


    // Calculate total price
    const calculateTotal = () => {
        return items.reduce((total, item) => total + (item.totalPrice), 0).toFixed(2);
    };
    // // getting address id
    const handleAddressSelect = (addressId) => {
        setSelectedAddressId(addressId);
        // console.log("Selected Address ID:", addressId);
    };

    // create order api
    const createOrder = async () => {
        if (!token) {
            toast.error('Please login first');
            return;
        }

        if (!selectedAddressId) {
            toast.error('Please select delivery address');
            return;
        }
        try {
            const res = await axios.post(`${baseUrl}/order-store`,
                {
                    cart_id: cartId,
                    user_id: userId,
                    address: selectedAddressId,
                    coupon_code: couponCode,
                    payment_method: selectedPaymentMethod,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
            console.log("order success", res.data)
            if (res.data.status) {
                toast.success(res.data.message)
                setOrderId(res.data.data.order_id)
                const successModalEl = document.querySelector('#success');
                if (successModalEl) {
                    const successModal = new window.bootstrap.Modal(successModalEl);
                    successModal.show();
                }
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("error create order", error)
        }
    }

    // delete caart ITem
    const handleRemoveItem = (productId) => {
        dispatch(deleteCartItemApi({ productId }))
    }

    const handleIncreaseQuantity = async (productId, currentQuantity, stockQty) => {
        if (currentQuantity >= stockQty) {
            toast.info("Cannot add more items than available stock");
            return;
        }
        const newQuantity = currentQuantity + 1;
        try {
            // Optimistically update the UI
            dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
            // Make the API call
            await dispatch(updateCartQuantityAPI(productId, newQuantity));
        } catch (error) {
            toast.error("Failed to update quantity");
        } 
    };

    const handleDecreaseQuantity = async (productId, currentQuantity) => {
        if (currentQuantity > 1) {
            try {
                const newQuantity = currentQuantity - 1;
                dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
                await dispatch(updateCartQuantityAPI(productId, newQuantity));
            } catch (error) {
                toast.error("Failed to update quantity");
            } 
        }
    };

    const handleInputChange = async (productId, event) => {
        const newQuantity = parseInt(event.target.value, 10);
        if (!isNaN(newQuantity) && newQuantity >= 1) {
            try {
                dispatch(updateCartQuantity({ productId, quantity: newQuantity }));
                await dispatch(updateCartQuantityAPI(productId, newQuantity));
            } catch (error) {
                toast.error("Failed to update quantity");
            } 
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Checkout</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Checkout
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="checkout tp-space">
                <div className="container">
                    <div className="row">
                        {
                            !token && (
                                <div className="col-lg-12">
                                    <div className="checkout-head">
                                        <p className="text-dark mb-0">
                                            Already have an account?{" "}
                                            <a href="javascript:void(0);" data-bs-toggle="modal"
                                                data-bs-target="#login" className="txt-org fw-600">
                                                Click here to login
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            )
                        }


                        <Address onAddressSelect={handleAddressSelect} />
                        <div className="col-lg-4">
                            <div className="my-order cart-card bg-white rounded-0 p-0">
                                <h3>My Order</h3>
                                <div className="p-2">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th />
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                items && items.map((item, index) => {
                                                    return (
                                                        <tr key={item?._id}>
                                                            <td>
                                                                <img
                                                                    src={`${imgUrl}/${item?.product_id?.product_images[0].image}`}
                                                                    className="img-40"
                                                                    alt=""
                                                                />
                                                            </td>
                                                            <td>
                                                                <span className="clr">{item?.product_id?.product_name}</span>
                                                                <p className="mb-0"> QTY. : </p>
                                                                <div className="quantity">
                                                                    <button
                                                                        type="button"
                                                                        className="minus"
                                                                        onClick={() => handleDecreaseQuantity(item?.product_id?._id, item.quantity)}
                                                                    >
                                                                            <i className="fas fa-minus" />
                                                                    </button>
                                                                    <input
                                                                        className="number"
                                                                        type="number"
                                                                        value={item.quantity}
                                                                        onChange={(e) => handleInputChange(item?.product_id?._id, e)}
                                                                        max={item?.product_id?.stock_qty}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="plus"
                                                                        onClick={() => handleIncreaseQuantity(item?.product_id?._id, item.quantity, item.product_id?.stock_qty)}
                                                                    >
                                                                            <i className="far fa-plus" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p className="price">
                                                                    {item?.product_id?.price} <del>{item?.product_id?.discount_price}</del>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() => handleRemoveItem(item?.product_id?._id)}
                                                                    className="text-danger ms-1"
                                                                >
                                                                    <i className="fas fa-trash-alt" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className="apply-cupon">
                                        <div className="apply-cupon-innr">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Discount code or gift card"
                                                value={couponCode}
                                                onChange={(e) => setCouponCode(e.target.value)}
                                            />
                                            <button className="thm-btn dark" onClick={verifyCoupon} type="button">
                                                Apply
                                            </button>
                                        </div>
                                    </div>
                                    <ul className="bill-summary">
                                        <li>
                                            <span>Subtotal</span>
                                            <span>{calculateTotal()}</span>
                                        </li>
                                        {discount > 0 && (
                                            <li>
                                                <span>Discount</span>
                                                <span>{discount}</span>
                                            </li>
                                        )}
                                        <li>
                                            <span>CGST</span>
                                            <span>$10.00</span>
                                        </li>
                                        <li>
                                            <span>SGST</span>
                                            <span>$10.00</span>
                                        </li>
                                        <li>
                                            <span>Grand Total</span>
                                            <span className="txt-org">{calculateTotal() - discount}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="delivery-option-bx">
                                <h5>Delivery Partner</h5>
                                <label htmlFor="">Select Delivery Partner</label>
                                <select name="" id="" className="form-select">
                                    <option value="">Delivery Partner</option>
                                </select>
                            </div> */}
                            <div className="payment">
                                <h5 className="fz-22 fw-600">Payment Option</h5>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-01"
                                        value="Debit/Credit Card"
                                        checked={selectedPaymentMethod === "Debit/Credit Card"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-01">
                                        {" "}
                                        Debit/ Credit Card{" "}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-02"
                                        value="Net Banking"
                                        checked={selectedPaymentMethod === "Net Banking"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-02">
                                        Net Banking
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-03"
                                        value="Google Pay"
                                        checked={selectedPaymentMethod === "Google Pay"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-03">
                                        Google Pay
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="payment"
                                        id="pay-04"
                                        value="COD"
                                        checked={selectedPaymentMethod === "COD"}
                                        onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="pay-04">
                                        Cash On Delivery
                                    </label>
                                </div>
                                <button type='button' onClick={createOrder} className="thm-btn w-100 text-center">
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* order-success-start */}
            <div
                className="modal fade success-order"
                id="success"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img src={successcart} alt="success-cart" />
                            <h6>Order Successful</h6>
                            <p>
                                MrJane Cooper, your order is with us now ...relax and we will soon
                                update you how soon the order can reach you...
                            </p>
                            <div className="mt-3 mb-3">
                                <Link
                                    to="/"
                                    // data-bs-dismiss="modal"
                                    onClick={() => {
                                        const successModalEl = document.querySelector('#success');
                                        const successModal = window.bootstrap.Modal.getInstance(successModalEl);
                                        if (successModal) {
                                            successModal.hide();
                                        }
                                    }}
                                    className="thm-btn danger me-2"
                                >
                                    Back To home
                                </Link>
                                <Link to='/myorder' onClick={() => {
                                    const successModalEl = document.querySelector('#success');
                                    const successModal = window.bootstrap.Modal.getInstance(successModalEl);
                                    if (successModal) {
                                        successModal.hide();
                                    }
                                }} className="thm-btn dark">
                                    My Order
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* oreder-success-end */}
        </>

    )
}

export default Checkout