import React from 'react'
import corparate from "../../assets/images/corparate-img-01.png";
import corparate2 from "../../assets/images/corparate-img-02.png";
import corparate3 from "../../assets/images/corparate-img-03.png";
import corparate4 from "../../assets/images/corparate-img-04.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
const CorporateGiftingSlider = () => {
    const options1 = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        // items: 4,
        responsive:{
            0:{
                items:1,
                nav:true,
            },
            350:{
                items:1.5,
                nav:true,
            },
            576: {
                items: 1.5,
                nav: true
            },
            768: {
                items: 2,
                nav: true
            },
            992: {
                items: 4,
                nav: true
            },
            1200: {
                items: 4,
                nav: true
            }
        }
    };
    return (
        <>
            <section className="corparate-sec tp-space">
                <div className="container">
                    <div className="heading">
                        <h3 className="title">
                            Corporate <span>Gifting</span>
                        </h3>
                        <Link to="/corporategift" className="thm-btn light">
                            View More
                        </Link>
                    </div>
                    <OwlCarousel className="corporate-slide owl-theme owl-carousel" {...options1}>
                        <div className="corparate-img">
                            <Link to="/corporategift">
                                <img src={corparate} alt="corparate" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/corporategift">
                                <img src={corparate2} alt="corparate2" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/corporategift">
                                <img src={corparate3} alt="corparate3" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/corporategift">
                                <img src={corparate4} alt="corparate4" />
                            </Link>
                        </div>
                        <div className="corparate-img">
                            <Link to="/corporategift">
                                <img src={corparate2} alt="corparate" />
                            </Link>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
        </>
    )
}

export default CorporateGiftingSlider