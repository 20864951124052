import React from 'react'
import errorImg from "../assets/images/error-img.png"
import { Link } from 'react-router-dom'
const PageNotFound = () => {
    return (
        <>
            <section className="error-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="error-innr">
                                <img src={errorImg} alt="error-img" />
                                <h3>Sorry, Something Went Wrong</h3>
                                <p>
                                    The page you are looking for might have been removed had its name
                                    changed or is temporarily unavailable.
                                </p>
                                <Link to="/" className="thm-btn dark">
                                    Back To Home Page
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageNotFound