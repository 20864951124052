import React, { useEffect, useMemo, useState } from 'react'
import check from "../../assets/images/check.svg";
import { Link } from 'react-router-dom';
import StarRating from '../Helper/StarRating';
import { imgUrl } from '../Helper/BaseUrl';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartAPI, deleteCartItemApi, updateCartQuantityAPI } from '../features/cartSlice';
import { toast } from 'react-toastify';
import { fetchWishlist, toggleWishlistApi } from '../features/wishlistSlice';
import PriceDisplay from '../PriceDisplay';
const NewArrivalSlider = ({ product, user }) => {
    // console.log("newproduct",product)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
 
    const [isIncrementLoading, setIsIncrementLoading] = useState(false);
    const [isDecrementLoading, setIsDecrementLoading] = useState(false);
    const [isWishlistLoading, setIsWishlistLoading] = useState(false);
    const [localWishlistStatus, setLocalWishlistStatus] = useState(false);
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    const wishlistItems = useSelector(state => {
        return state.wishlist?.wishlistItems?.products || [];
    });
    useEffect(() => {
        const isInWishlist = wishlistItems?.some(item =>
            item?.product_id?._id === product._id
        );
        setLocalWishlistStatus(isInWishlist);
    }, []);
    const cartItem = cartItems.find(item => item?.product_id?._id === product._id) || null;
    const isInCart = Boolean(cartItem);
    const handleIncrement = async () => {
        if (cartItem) {
            // Check if next quantity would exceed stock
            if (cartItem.quantity >= product.stock_qty) {
                toast.info(`Sorry, only ${product.stock_qty} items available in stock`);
                return;
            }
            setIsIncrementLoading(true);
            try {
                await dispatch(updateCartQuantityAPI(
                    cartItem?.product_id?._id,
                    cartItem.quantity + 1
                ))
            } finally {
                setIsIncrementLoading(false);
            }
        }
    };

    const handleDecrement = async () => {
        if (cartItem) {
            setIsDecrementLoading(true);
            try {
                if (cartItem.quantity > 1) {
                    await dispatch(updateCartQuantityAPI(
                        cartItem?.product_id?._id,
                        cartItem.quantity - 1
                    ))
                } else {
                    await dispatch(deleteCartItemApi({
                        productId: cartItem?.product_id?._id
                    }))
                }
            } finally {
                setIsDecrementLoading(false);
            }
        }
    };

    const handleAddToCart = () => {
        const existingItem = cartItems.find(item => item.product_id._id === product._id);
        if (existingItem) {
            toast.info("Item already exists in the cart");
        } else {
            dispatch(addToCartAPI({ ...product }));
        }
    };
    const handleAddToWishlist = async (productId) => {
        if (!user?._id) {
            toast.error("Please login to add items to wishlist");
            return;
        }
        try {
            setIsWishlistLoading(true);
            setLocalWishlistStatus(!localWishlistStatus);
            await dispatch(toggleWishlistApi(productId, user?._id));
            // Refresh wishlist after toggle
            dispatch(fetchWishlist());
        } catch (error) {
            // Revert on error
            setLocalWishlistStatus(localWishlistStatus);
            toast.error("Failed to update wishlist");
        } finally {
            setIsWishlistLoading(false);
        }
    };
    return (
        <>
            <div key={product._id} className="product-card">
                <div className="product-img">
                    <img src={`${imgUrl}/${product.product_images[0].image}`} alt={product.name} />
                </div>
                <div className="product-content">
                    <p className="auth">
                        {
                            product.authentic && (
                                <span className='d-flex'>
                                    <img src={check} className="me-1" alt="check" />
                                    Authentic{" "}
                                </span>
                            )
                        }

                        {
                            product.in_stock ? (
                                <span className="text-success">
                                    <i className="fas fa-check me-1" />
                                    In stock
                                </span>
                            ) : (
                                <span className="text-success">
                                    out of stock
                                </span>
                            )
                        }

                    </p>
                    <h5>
                        <Link to={`/productdetails/${product._id}`}>{product.product_name}</Link>
                    </h5>
                    <p className="rating">
                        {/* <StarRating rating = {product.rating} /> */}
                        <span className="fas fa-star" />
                        <span className="fas fa-star" />
                        <span className="fas fa-star" />
                        <span className="fas fa-star" />
                        <span className="fa-regular fa-star" />
                    </p>
                    <p className="price"><PriceDisplay priceUSD={product.price} /><del> {
                        product.is_discount && <PriceDisplay priceUSD={product.discount_price} />}</del>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                        <button
                            className="fav-btn"
                            onClick={() => handleAddToWishlist(product._id)}
                        >
                            <i className={`${localWishlistStatus ? 'fas fa-heart' : 'fa-regular fa-heart'}`} />
                        </button>
                        {!isInCart ? (
                            <button
                                onClick={handleAddToCart}
                                className="thm-btn"
                                disabled={!product.in_stock}
                            >
                                <i className="fas fa-shopping-basket me-1" />
                                Add to Cart
                            </button>
                        ) : (
                            <div className="quantity-controls d-flex align-items-center">
                                <button
                                    onClick={handleDecrement}
                                    className="btn btn-outline-secondary"
                                    disabled={isDecrementLoading}
                                >
                                    {isDecrementLoading ? (
                                        <span className="spinner-border spinner-border-sm" />
                                    ) : (
                                        '-'
                                    )}
                                </button>
                                <span className="mx-2">{cartItem?.quantity || 0}</span>
                                <button
                                    onClick={handleIncrement}
                                    className="btn btn-outline-secondary"
                                // disabled={cartItem?.quantity >= product?.stock_qty}

                                >
                                    {isIncrementLoading ? (
                                        <span className="spinner-border spinner-border-sm" />
                                    ) : (
                                        '+'
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewArrivalSlider