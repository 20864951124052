import { createSlice } from "@reduxjs/toolkit"
import baseUrl from "../Helper/BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

// const getLocalData = () => {
//   try {
//     const cartData = localStorage.getItem("myCartItem");
//     const parsedData = JSON.parse(cartData);
//     return Array.isArray(parsedData) ? parsedData : [];
//   } catch {
//     return [];
//   }
// };
const isLogin = localStorage.getItem("user")
const getCartData = JSON.parse(localStorage.getItem('cartItems'));
const initialState = {
  items: getCartData || [],
  loading: false,
  error: null,
  cartId: null,
}
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addToCartSuccess(state, action) {
      // console.log("cart_id",action.payload)
      const product = action?.payload?.products || action.payload;
      if (!product) {
        return;
      }
      // console.log("cartslice", state.items)
      const existingProduct = state.items.find(item => item.product_id === product.product_id);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        state.items = [...state.items, product];
      }
      isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    },
    setCartItems(state, action) {
      // console.log("cart_id", action.payload)
      if (!action.payload) {
        state.items = [];
        state.cartId = null;
      } else {
      state.items = action.payload.products || [];
        state.cartId = action.payload._id;
      }
      state.loading = false;
      state.error = null;
      isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    },
  
    //   const productId = action.payload;
    //   const item = state.items.find(item => item._id === productId);
    //   if (item) {
    //     item.quantity += 1;
    //     isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    //   }
    // },

    // decrementQuantity(state, action) {
    //   const productId = action.payload;
    //   const item = state.items.find(item => item._id === productId);
    //   if (item && item.quantity > 1) {
    //     item.quantity -= 1;
    //     isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    //   }
    // },

    updateCartQuantity(state, action) {
      const { productId, quantity } = action.payload;
      // console.log("qty",productId, quantity)
      const item = state.items.find(item => item._id === productId);
      if (item) {
        item.quantity = quantity;
        isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
      }
    },
    removeFromCart(state, action) {
      state.items = state.items.filter(item => item._id !== action.payload.productId)
      isLogin && localStorage.setItem('cartItems', JSON.stringify(state.items));
    },
    clearCart(state) {
      state.items = [];
      isLogin && localStorage.removeItem('cartItems');
    },
  }
})

export const { setLoading,
  setError,
  addToCartSuccess,
  setCartItems,
  updateCartQuantity,
  removeFromCart,
  clearCart, } = cartSlice.actions;



// API action to add an item to the cart
export const addToCartAPI = (product) => async (dispatch) => {
  const userLogin = localStorage.getItem('user')
  if (!userLogin) {
    toast.error("Please log in to add items to the cart.");
    return;
  }
  const user = JSON.parse(userLogin);
  dispatch(setLoading());

  try {
    // First check if product exists in cart
    // const cartResponse = await axios.get(`${baseUrl}/user-cart-view/${user._id}`, {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('token')}`
    //   }
    // });
    // // console.log("96",cartResponse)
    // const existingProduct = cartResponse.data.data.products.find(
    //   item => item.product_id._id === product._id
    // );

    // if (existingProduct) {
    //   // If product exists, update quantity
    //   return dispatch(updateCartQuantityAPI(product._id, existingProduct.quantity + 1));
    // }

    // If product doesn't exist, add new product
    const response = await axios.post(`${baseUrl}/cart-store`, {
      product_id: product._id,
      user_id: user._id,
      name: product.product_name,
      quantity: 1,
      price: product.price,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    const { status, message, data } = response.data;
    if (status) {
      toast.success(message);
      dispatch(setLoading(false));
      dispatch(addToCartSuccess(data.products || data));
      dispatch(fetchCartProducts());
    } else {
      dispatch(setError(message));
      dispatch(setLoading(false));
      toast.error(message);
    }
  } catch (error) {
    dispatch(setError(error.message || 'An error occurred while adding products.'));
    dispatch(setLoading(false));
    toast.error('Failed to add products.');
    console.log("add to cart error", error);
  }
};


// Async thunk for fetching cart data from API
export const fetchCartProducts = () => async (dispatch) => {
  const userLogin = localStorage.getItem('user');
  const token = localStorage.getItem('token');
  if (!userLogin || !token) {
    dispatch(setCartItems(null)); // This will clear the cart
    return;
  }
  if (!userLogin) {
    toast.error("Please log in to view your cart");
    return;
  }
  const user = JSON.parse(userLogin);

  try {
    dispatch(setLoading());
    const res = await axios.get(`${baseUrl}/user-cart-view/${user._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });
    const { status, message, data, } = res.data;
    // console.log(res)

    if (status) {
      dispatch(setCartItems(data));
    } else {
      toast.error(message);
      dispatch(setError(message));
    }
  } catch (error) {
    toast.error("Error fetching cart data.", error);
    dispatch(setError(error.message));
    dispatch(setCartItems(null));
  }
};


// API action to update cart item quantity
export const updateCartQuantityAPI = (productId, quantity) => async (dispatch) => {
  // console.log(productId,quantity)
  const userLogin = localStorage.getItem("user");
  if (!userLogin) {
    toast.error("Please log in to update cart item quantity");
    return;
  }

  const user = JSON.parse(userLogin);
  try {
    dispatch(setLoading(false));
    // Make API request to update cart item quantity
    const response = await axios.post(
      `${baseUrl}/user-cart-change-quantity/${user._id}/${productId}`,
      { quantity },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }
    );
    // console.log("1212", response)
    const { status, message, data } = response.data;
    dispatch(setLoading(false));
    if (status) {
      toast.success(message);
      // dispatch(setCartItems(data));
      dispatch(updateCartQuantity({ productId, quantity }));
      dispatch(setLoading(false));
      dispatch(fetchCartProducts())
    } else {
      toast.error(message);
      dispatch(setError(message))
    }
  } catch (error) {
    console.log(error)
    dispatch(setError(error.message))
    toast.error("Error updating cart item quantity.", error);
  }
     finally {
      dispatch(setLoading(false));  // Always clear loading state
    }
};


// API action to update cart item quantity
export const deleteCartItemApi = (productId) => async (dispatch) => {
  const userLogin = localStorage.getItem("user");
  if (!userLogin) {
    toast.error("Please log in to delete cart item");
    return;
  }
  let user;
  try {
    user = JSON.parse(userLogin);
  } catch (error) {
    toast.error("Invalid user data.");
    return;
  }
  try {
    dispatch(setLoading());
    const response = await axios.delete(
      `${baseUrl}/user-cart-product-delete/${user._id}/${productId?.productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }
    );
    // console.log("1212", response.data.data)
    const { status, message, data } = response.data;
    if (status) {
      toast.success(message);
      dispatch(removeFromCart(productId));
      dispatch(fetchCartProducts())
    } else {
      toast.error(message);
      dispatch(setError(message))
    }
  } catch (error) {
    console.log(error)
    dispatch(setError(error.message || "Error deleting cart item."))
    toast.error("Error deleting cart item.", error);
  }
};


export default cartSlice.reducer;