import React, { useState } from 'react'
import facebook2 from "../../assets/images/facebook-02.svg";
import instagram2 from "../../assets/images/instagram-02.svg";
import google2 from "../../assets/images/google-2.svg";
import registration from "../../assets/images/registration-img.png";
import rediff from "../../assets/images/rediff.svg";
import bing from "../../assets/images/bing.svg";
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl from '../Helper/BaseUrl';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../features/authSlice';

const LoginModal = () => {
    const [values, setValues] = useState({
        mobile: "",
        password: "",
    });
    const [loading, setLoading] = useState(false)
    const customId = "custom-id-yes";
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }
    // console.log(values)
    const validateLogin = () => {
        const { mobile, password } = values;
        if (!mobile?.trim()) {
            toast.error('Please Enter Phone Number');
            return false;
        }
        if (!/^\d{10}$/.test(mobile)) {
            toast.error('Phone Number must be exactly 10 digits.');
            return false;
        }
        if (!password) {
            toast.error('Please Enter Password');
            return false;
        }
        return true;
    }
    // console.log("+", values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateLogin()) {
            // console.log("+", values)
            setLoading(true)
            try {
                const response = await axios.post(`${baseUrl}/login`, values)
                // console.log("login", response.data.message)
                if (response.data.status) {
                    const user = response.data.data;
                    const token = response.data.token;
                    // console.log(user,token)
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    dispatch(login({ user, token }))
                    toast.success(response.data.message)
                    const loginModalEl = document.getElementById('login');
                    const LoginInstance = window.bootstrap.Modal.getInstance(loginModalEl)
                    LoginInstance.hide();
                    navigate('/')
                    setValues({
                        mobile: "",
                        password: "",
                    });
                    setLoading(false)
                } else {
                    setLoading(false)
                    toast.error(response.data.message || response.data.msg)
                }
            } catch (error) {
                setLoading(false)
                console.log('login api error', error)
            }
        }

    }
    return (
        <>
            {/* {
                loading && (
                    <div className="loader-main">
                        <div className="loader1"></div></div>
                )} */}
            <div
                className="modal fade registration"
                id="login"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                        <div className="modal-body">
                            <div className="row gx-0">
                                <div className="col-lg-6 h-100 d-xl-block d-lg-block d-none">
                                    <div className="registration-img">
                                        <img
                                            src={registration}
                                            className="w-100 h-100"
                                            alt="registration"
                                        />
                                        <div className="registration-content">
                                            <h3 className="text-white">Sign In</h3>
                                            <p className="text-white">
                                                Enter your credentials to log into your account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="registration-frm">
                                        <form action="" onSubmit={handleSubmit}>
                                            <div className="registration-bx">
                                                <label htmlFor="">Mobile Number</label>
                                                <input
                                                    type="tel"
                                                    name='mobile'
                                                    value={values.mobile}
                                                    onChange={handleChange}
                                                    className="form-control mobile"
                                                    placeholder="Enter Number"
                                                />
                                            </div>
                                            <div className="registration-bx">
                                                <label htmlFor="">Password</label>
                                                <input
                                                    type="password"
                                                    name='password'
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                />
                                            </div>
                                            <div className="text-end mb-3">
                                                <a
                                                    href="javascript:void(0);"
                                                    className="clr"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#forgot"
                                                >
                                                    Forgot Password
                                                </a>
                                            </div>
                                            <div className="registration-bx">
                                                <button
                                                    className="thm-btn dark w-100 rounded-5 p-3"
                                                    type="submit"
                                                    disabled={loading} 
                                                >
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    ) : (
                                                        "Sign In"
                                                    )}
                                                    {/* Sign In */}
                                                </button>
                                            </div>
                                            <p className="text-center fz-18 text-dark">OR</p>
                                            <div className="social-media">
                                                <a href="javascript:void(0);">
                                                    <img src={google2} alt="google2" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={facebook2} alt="facebook2" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={instagram2} alt="instagram2" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={rediff} alt="rediff" />
                                                </a>
                                                <a href="javascript:void(0);">
                                                    <img src={bing} alt="bing" />
                                                </a>
                                            </div>
                                            <p className="clr fz-18 text-center mt-4">
                                                New to website name?{" "}
                                                <a
                                                    href="javascript:void(0);"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#register"
                                                    className="txt-org fw-600"
                                                >
                                                    Create an account
                                                </a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginModal