import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchProducts, setSortType } from './features/productSlice';
import ProductSort from './ProductSort';
import ProductData from './ProductData';
import { fetchWishlist } from './features/wishlistSlice';
import product from '../assets/images/product-img-01.png'
import check from '../assets/images/check.svg'
function ViewMore() {
    const dispatch = useDispatch();
    const { products, isloading, sortType, isError } = useSelector((state) => state.product);
    const user = useSelector((state) => state.auth.user);
    // console.log("aaya", products)
    // const user = useSelector((state) => state.auth);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchProducts());
        if (user?._id) {
            dispatch(fetchWishlist());
        }
    }, [dispatch, user?._id]);
    useEffect(() => {
        if (isError) {
            toast.error(isError);
        }
    }, [isError]);

    const getSortedProducts = () => {
        if (!products) return [];

        const sortedProducts = [...products];
        // console.log(sortedProducts)
        switch (sortType) {
            case 'lowest':
                return sortedProducts.sort((a, b) => a.price - b.price);
            case 'highest':
                return sortedProducts.sort((a, b) => b.price - a.price);
            case 'ascending':
                return sortedProducts.sort((a, b) => (a.product_name).localeCompare(b.product_name));
            case 'descending':
                return sortedProducts.sort((a, b) => (b.product_name).localeCompare(a.product_name));
            default:
                return sortedProducts;
        }
    };
    const sortedProducts = getSortedProducts();
    if (isloading) {
        return <div className='loader-main'>
            <div className="loaderaa">
            </div>
        </div>
    }
    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>View All</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        View All
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tp-space shop-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="product-head justify-content-between">
                                <p className="mb-0 text-dark fw-500">{sortedProducts.length}-Products Found</p>
                                <ProductSort sortType={sortType} />
                            </div>

                            <div className='row'>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="product-card">
                                        <div className="product-img">
                                            <img src={product} alt="product" />
                                        </div>
                                        <div className="product-content">
                                            <p className="auth">
                                                <span className='d-flex'>
                                                    <img src={check} className="me-1" alt="check" />
                                                    Authentic{" "}
                                                </span>
                                                <span className="text-success">
                                                    <i className="fa fa-check me-1" />
                                                    In stock
                                                </span>
                                            </p>
                                            <h5>
                                                <Link to="/productdetails">Ground Nuts Oil Snack</Link>
                                            </h5>
                                            <p className="rating">
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fas fa-star" />
                                                <span className="fa-regular fa-star" />
                                            </p>
                                            <p className="price">
                                                $28.85 <del>$33.85</del>
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a href="javascript:void(0);" className="fav-btn">
                                                    <i className="fa-regular fa-heart" />
                                                </a>
                                                <a href="javascript:void(0);" className="thm-btn">
                                                    <i className="fas fa-shopping-basket me-1" />
                                                    Add to Cart
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewMore