import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from 'axios';
import baseUrl from './Helper/BaseUrl';
import Swal from 'sweetalert2';
const Address = ({onAddressSelect}) => {
    const user = useSelector((state) => state.auth.user)
    // console.log(user._id)
    const userId = user._id;
    const [addressList, setAddressList] = useState([])
    const [showAll, setShowAll] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const [currentAddressId, setCurrentAddressId] = useState(null);

    const [selectedAddressId, setSelectedAddressId] = useState('');
    const[isInitialLoad, setIsInitialLoad] = useState(true);
    const [values, setValues] = useState({
        user_id: userId,
        billingAddress: {
            mobileNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            fullAddress: '',
            landmark: '',
            floor: '',
            country: '',
            city: '',
            state: '',
            pinCode: ''
        },
        Ship_different_address: false,
        shippingAddress: {
            mobileNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            fullAddress: '',
            landmark: '',
            floor: '',
            country: '',
            city: '',
            state: '',
            pinCode: ''
        }
    });
    const customId = "custom-id-yes";
    //onchange
    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobileNumber') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prev => ({
                    ...prev,
                    billingAddress: {
                        ...prev.billingAddress,
                        [name]: numericValue
                    }
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prev => ({
                ...prev,
                billingAddress: {
                    ...prev.billingAddress,
                    [name]: value
                }
            }));
        }

    };
    //onchange
    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobileNumber') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prev => ({
                    ...prev,
                    shippingAddress: {
                        ...prev.shippingAddress,
                        [name]: numericValue
                    }
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prev => ({
                ...prev,
                shippingAddress: {
                    ...prev.shippingAddress,
                    [name]: value
                }
            }));
        }

    };
    // view more and view less button
    const toggleView = () => {
        setShowAll(!showAll);
    };
    // address list api
    const fetchAddress = async () => {
        try {
            const res = await axios.get(`${baseUrl}/address-user-list/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log("add", res.data.data)
            setAddressList(res.data.data)
        } catch (error) {
            console.log("fetch address error", error)
        }
    }
    //validation
    const validateAddress = () => {
        // Validate billing address
        const billing = values.billingAddress;
        if (!billing.mobileNumber) {
            toast.error('Billing Mobile Number is required');
            return false;
        }
        if (!billing.firstName) {
            toast.error('Billing First Name is required');
            return false;
        }
        if (!billing.lastName) {
            toast.error('Billing Last Name is required');
            return false;
        }
        if (!billing.email) {
            toast.error('Billing Email is required');
            return false;
        }
        if (!billing.fullAddress) {
            toast.error('Billing Full Address is required');
            return false;
        }
        // if (!billing.landmark) {
        //     toast.error('Billing Landmark is required');
        //     return false;
        // }
        if (!billing.floor) {
            toast.error('Billing Floor/House No. is required');
            return false;
        }
        if (!billing.country) {
            toast.error('Billing Country is required');
            return false;
        }
        if (!billing.state) {
            toast.error('Billing State is required');
            return false;
        }
        if (!billing.city) {
            toast.error('Billing City is required');
            return false;
        }
        if (!billing.pinCode) {
            toast.error('Billing Pin Code is required');
            return false;
        }

        // If shipping to different address, validate shipping address
        if (values.Ship_different_address) {
            const shipping = values.shippingAddress;
            if (!shipping.mobileNumber) {
                toast.error('Shipping Mobile Number is required');
                return false;
            }
            if (!shipping.firstName) {
                toast.error('Shipping First Name is required');
                return false;
            }
            if (!shipping.lastName) {
                toast.error('Shipping Last Name is required');
                return false;
            }
            if (!shipping.email) {
                toast.error('Shipping Email is required');
                return false;
            }
            if (!shipping.fullAddress) {
                toast.error('Shipping Full Address is required');
                return false;
            }
            // if (!shipping.landmark) {
            //     toast.error('Shipping Landmark is required');
            //     return false;
            // }
            if (!shipping.floor) {
                toast.error('Shipping Floor/House No. is required');
                return false;
            }
            if (!shipping.country) {
                toast.error('Shipping Country is required');
                return false;
            }
            if (!shipping.state) {
                toast.error('Shipping State is required');
                return false;
            }
            if (!shipping.city) {
                toast.error('Shipping City is required');
                return false;
            }
            if (!shipping.pinCode) {
                toast.error('Shipping Pin Code is required');
                return false;
            }
        }

        return true;
    };

    // fetch single address api
    const handleEditAddress = async (addressId) => {
        setIsEditing(true)
        setCurrentAddressId(addressId)
        try {
            const res = await axios.get(`${baseUrl}/address-view/${addressId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });

            // Populate the form with existing address data
            setValues({
                user_id: userId,
                billingAddress: res.data.data.billingAddress,
                Ship_different_address: res.data.data.Ship_different_address,
                shippingAddress: res.data.data.shippingAddress
            });

            // Open the modal
            const addressModal = document.querySelector('#add-address');
            const bsModal = new window.bootstrap.Offcanvas(addressModal);
            bsModal.show();
        } catch (error) {
            console.log("fetch address error", error);
            toast.error("Error fetching address details");
        }
    }

    // update and store api
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateAddress()) {
            return;
        }
        try {
            let res;
            if (isEditing) {
                // Update existing address
                res = await axios.post(`${baseUrl}/address-update/${currentAddressId}`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else {
                // Create new address
                res = await axios.post(`${baseUrl}/address-store`, values, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            }
            // console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                const offcanvasElement = document.getElementById('add-address');
                const offcanvas = window.bootstrap.Offcanvas.getInstance(offcanvasElement);
                offcanvas.hide();

                setIsEditing(false);
                setCurrentAddressId(null);
                await fetchAddress();
                // If this was the first address, select it automatically
                // if (addressList.length === 0) {
                //     const newAddressId = res.data.data._id;
                //     setSelectedAddressId(newAddressId);
                //     onAddressSelect(newAddressId);
                // }
                // Reset form
                setValues({
                    user_id: userId,
                    billingAddress: {
                        mobileNumber: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        fullAddress: '',
                        landmark: '',
                        floor: '',
                        country: '',
                        city: '',
                        state: '',
                        pinCode: ''
                    },
                    Ship_different_address: false,
                    shippingAddress: {
                        mobileNumber: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        fullAddress: '',
                        landmark: '',
                        floor: '',
                        country: '',
                        city: '',
                        state: '',
                        pinCode: ''
                    }
                });
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log("address store error", error)
        }
    };
    // address delete api 
    const handleDeleteAddress = (addressId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover your address after deletion!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${baseUrl}/address-delete/${addressId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    if (res.data.status) {
                        toast.success(res.data.message);
                        // navigate('/')
                        fetchAddress();
                    } else {
                        toast.error(res.data.message)
                    }
                } catch (error) {
                    console.log("address delete error", error);
                    toast.error('Failed to delete address');
                }
            }
        });
    };
    const handleAddressSelection = (addressId) => {
        setSelectedAddressId(addressId);
        onAddressSelect(addressId);
    };
  
    // useEffect(() => {
    //     // If there are addresses but none selected, select the first one
    //     if (addressList.length > 0 && !selectedAddressId) {
    //         const initialAddressId = addressList[0]._id;
    //         setSelectedAddressId(initialAddressId);
    //         onAddressSelect(initialAddressId);
    //     }
    //     // If no addresses, show the add address modal
    //     else if (addressList.length === 0 && isInitialLoad) {
    //         const addressModal = document.querySelector('#add-address');
    //         if (addressModal) {
    //             const bsModal = new window.bootstrap.Offcanvas(addressModal);
    //             bsModal.show();
    //         }
    //     }
    //     // Set initial load to false after first run
    //     setIsInitialLoad(false);
    // }, [addressList]);
    
    useEffect(() => {
        fetchAddress();
    }, [])
    return (
        <>
            <div className="col-lg-8">
                <div className="checkout-add p-0">
                    <h4>
                        <span>Address</span>
                        <a
                            href="javascript:void(0);"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#add-address"
                            aria-controls="offcanvasRight"
                            className="thm-btn dark"
                        >
                            Add New Address
                        </a>

                    </h4>
                    <div className="p-3">
                        {addressList.length === 0 ? (
                            <div className="text-center ">
                                <p className="fz-16 txt-dark">No addresses available. Please add a new address.</p>
                            </div>
                        ) : (<>
                            {
                                (showAll ? addressList : addressList.slice(0, 2)).map((address, index) => {
                                    return (
                                        <div className="checkout-add-innr" key={address._id}>
                                            <div className="select-add">
                                                <h5 className="fz-20 fw-600">Address {index + 1}</h5>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="address"
                                                        id={address._id}
                                                        checked={selectedAddressId === address._id}
                                                        onChange={() => handleAddressSelection(address._id)}

                                                    />
                                                </div>
                                            </div>
                                            <div className="p-3">
                                                <div className="row">
                                                    <div className='d-flex justify-content-end'>
                                                        <button type='button' onClick={() => handleEditAddress(address._id)}
                                                            className="txt-org bg-none bg-0" style={{ background: 0 }}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button type='button' onClick={() => handleDeleteAddress(address._id)}
                                                            className="text-danger bg-none bg-0" style={{ background: 0 }}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <h5 className="fz-16 fw-500">Billing Details</h5>
                                                        <p>
                                                            {address.billingAddress.firstName} {address.billingAddress.lastName},<br />
                                                            {address.billingAddress.fullAddress},{address.billingAddress.floor},{address.billingAddress.landmark},
                                                            {address.billingAddress.city}, {address.billingAddress.state}<br />
                                                            {address.billingAddress.country} - ({address.billingAddress.pinCode})
                                                            <a href="javascript:void(0);" className="clr d-block">
                                                                {address.billingAddress.mobileNumber}
                                                            </a>
                                                        </p>
                                                    </div>
                                                    {address.Ship_different_address && (
                                                        <div className="col-lg-6">
                                                            <h5 className="fz-16 fw-500">Shipping Address</h5>
                                                            <p>
                                                                {address.shippingAddress.firstName} {address.shippingAddress.lastName},<br />
                                                                {address.shippingAddress.fullAddress},{address.shippingAddress.floor},{address.shippingAddress.landmark},
                                                                {address.shippingAddress.city}, {address.shippingAddress.state}<br />
                                                                {address.shippingAddress.country} - ({address.shippingAddress.pinCode})
                                                                <a href="javascript:void(0);" className="clr d-block">
                                                                    {address.shippingAddress.mobileNumber}
                                                                </a>
                                                            </p>

                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {addressList.length > 2 && (
                                <div className="text-end mt-2">
                                    <a type='button' onClick={toggleView} className="clr">
                                        {showAll ? 'View Less' : 'View More'}
                                    </a>
                                </div>
                            )}
                        </>
                        )}
                    </div>
                </div>
                <div
                    className="offcanvas offcanvas-end address-canvas"
                    tabIndex={-1}
                    id="add-address"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">
                            {isEditing ? 'Edit Address' : 'Add New Address'}
                        </h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        >
                            <i className="fa fa-times" />
                        </button>
                    </div>
                    <div className="offcanvas-body">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="address-canvas-frm">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4>Billing Details</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Mobile Number</label>
                                            <input type="number" name="mobileNumber"
                                                value={values.billingAddress.mobileNumber}
                                                onChange={handleBillingChange} className="form-control mobile" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">First Name</label>
                                            <input type="text" name="firstName"
                                                value={values.billingAddress.firstName}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Last Name</label>
                                            <input type="text" name="lastName"
                                                value={values.billingAddress.lastName}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Email</label>
                                            <input type="email" name="email"
                                                value={values.billingAddress.email}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Full Address</label>
                                            <input type="text" name="fullAddress"
                                                value={values.billingAddress.fullAddress}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Landmark</label>
                                            <input type="text" name="landmark"
                                                value={values.billingAddress.landmark}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Floor/House No.</label>
                                            <input type="text" name="floor"
                                                value={values.billingAddress.floor}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Country</label>
                                            <select name="country"
                                                value={values.billingAddress.country}
                                                onChange={handleBillingChange} id="" className="form-select">
                                                <option value="">Select Country</option>
                                                <option value="india">India</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">State</label>
                                            <select name="state"
                                                value={values.billingAddress.state}
                                                onChange={handleBillingChange} className="form-select">
                                                <option value="">Select State</option>
                                                <option value="rajasthan">Rajashthan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">City</label>
                                            <input type="text" name="city"
                                                value={values.billingAddress.city}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Pin Code</label>
                                            <input type="number" name="pinCode"
                                                value={values.billingAddress.pinCode}
                                                onChange={handleBillingChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="custom-frm-bx">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="ship"
                                                    checked={values.Ship_different_address}
                                                    onChange={(e) => setValues(prev => ({ ...prev, Ship_different_address: e.target.checked }))}
                                                />
                                                <label
                                                    className="form-check-label mb-0"
                                                    htmlFor="ship"
                                                >
                                                    Ship to a different address?
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="address-canvas-frm">
                                {values.Ship_different_address && (
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4>Shipping Address</h4>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Mobile Number</label>
                                                <input type="number" name="mobileNumber"
                                                    value={values.shippingAddress.mobileNumber}
                                                    onChange={handleShippingChange} className="form-control mobile" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">First Name</label>
                                                <input type="text" name="firstName"
                                                    value={values.shippingAddress.firstName}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Last Name</label>
                                                <input type="text" name="lastName"
                                                    value={values.shippingAddress.lastName}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Email</label>
                                                <input type="email" name="email"
                                                    value={values.shippingAddress.email}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Full Address</label>
                                                <input type="text" name="fullAddress"
                                                    value={values.shippingAddress.fullAddress}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Landmark</label>
                                                <input type="text" name="landmark"
                                                    value={values.shippingAddress.landmark}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Floor/House No.</label>
                                                <input type="text" name="floor"
                                                    value={values.shippingAddress.floor}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Country</label>
                                                <select name="country"
                                                    value={values.shippingAddress.country}
                                                    onChange={handleShippingChange} id="" className="form-select">
                                                    <option value="">select country</option>
                                                    <option value="india">india</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">State</label>
                                                <select name="state"
                                                    value={values.shippingAddress.state}
                                                    onChange={handleShippingChange} id="" className="form-select">
                                                    <option value="">Select state</option>
                                                    <option value="rajasthan">Rajasthan</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">City</label>
                                                <input type="text" name="city"
                                                    value={values.shippingAddress.city}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="custom-frm-bx">
                                                <label htmlFor="">Pin Code</label>
                                                <input type="number" name="pinCode"
                                                    value={values.shippingAddress.pinCode}
                                                    onChange={handleShippingChange} className="form-control" />
                                            </div>
                                        </div>

                                    </div>
                                )}
                                <div className="col-lg-12">
                                    <button
                                        className="thm-btn w-100 p-3"
                                        type="submit"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#success"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Address
