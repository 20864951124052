import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchProducts, setSortType } from './features/productSlice';
import ProductSort from './ProductSort';
import FilterSection from './FilterSection';
import ProductData from './ProductData';
import { fetchWishlist } from './features/wishlistSlice';

const Shop = () => {

    const dispatch = useDispatch();
    const { products, isloading, sortType, isError } = useSelector((state) => state.product);
    const user = useSelector((state) => state.auth.user);
    // console.log("aaya", products)
    // const user = useSelector((state) => state.auth);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchProducts());
        if (user?._id) {
            dispatch(fetchWishlist());
        }
    }, [dispatch, user?._id]);
    useEffect(() => {
        if (isError) {
            toast.error(isError);
        }
    }, [isError]);

    // wishlistItems
    // const handleToggleWishlist = (product) => {
    //     const isProductInWishlist = isInWishlist(product._id);
    //     dispatch(toggleWishlist(product));
    //     if (isProductInWishlist) {
    //         toast.success('Item removed from wishlist');
    //     } else {
    //         toast.success('Item added to wishlist');
    //     }
    // };
    // const isInWishlist = (productId) => {
    //     return wishlistItems.some(item => item._id === productId);
    // };

    // Sort products function
    const getSortedProducts = () => {
        if (!products) return [];

        const sortedProducts = [...products];
        // console.log(sortedProducts)
        switch (sortType) {
            case 'lowest':
                return sortedProducts.sort((a, b) => a.price - b.price);
            case 'highest':
                return sortedProducts.sort((a, b) => b.price - a.price);
            case 'ascending':
                return sortedProducts.sort((a, b) => (a.product_name).localeCompare(b.product_name));
            case 'descending':
                return sortedProducts.sort((a, b) => (b.product_name).localeCompare(a.product_name));
            default:
                return sortedProducts;
        }
    };
    const sortedProducts = getSortedProducts();
    if (isloading) {
        return <div className='loader-main'>
            <div className="loaderaa">
            </div>
        </div>
    }


    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Shop</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Shop
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tp-space shop-sec">
                <div className="container">
                    <div className="row">
                        <FilterSection />
                        <div className="col-lg-9 col-md-8">
                            <div className="product-head justify-content-between">
                                <p className="mb-0 text-dark fw-500">{sortedProducts.length}-Products Found</p>
                                <ProductSort sortType={sortType} />
                            </div>
                            <>
                                <div className="product-sec">
                                    <div className="row">
                                        {sortedProducts && sortedProducts.length > 0 ? (

                                            sortedProducts.map((product) => (

                                                <ProductData key={product._id} product={product} user={user} />

                                            ))

                                        ) : (
                                            <div>No products available.</div>
                                        )}
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Shop