import React, { useEffect, useState } from 'react'
import thumb from "../assets/images/thumb-01.png"
import successIcon from "../assets/images/success-icon.gif"
import reviewIcon from "../assets/images/review-icon.png"
import cancelIcon from "../assets/images/cancel-icon.png";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from './Helper/BaseUrl';
import { useSelector } from 'react-redux';
const MyOrder = () => {
    const user = useSelector((state) => state.auth.user)
    // console.log(user._id)
    const userId = user._id;
    const [orderList, setOrderList] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [formData, setFormData] = useState({
        reason: '',
        details: '',
        payment: 'credit-card'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };
    // review form handling 
    const [values, setValues] = useState({
        rating: 0,
        review: '',
    });

    const [selectedStars, setSelectedStars] = useState(0)

    const handleStarClick = (value) => {
        setSelectedStars(value);
        setValues(prev => ({ ...prev, rating: value }));
    };

    const handleReviewChange = (e) => {
        setValues(prev => ({ ...prev, review: e.target.value }));
    };
    const [imgFile, setImgFile] = useState(null)
    const handleReviewSubmit = (e) => {
        e.preventDefault();

        const submitData = new FormData();
        submitData.append('rating', values.rating);
        submitData.append('review', values.review);
        if (imgFile) {
            submitData.append('image', imgFile)
        }

        console.log("values", values)
        setValues({
            rating: 0,
            review: '',
        });
        setImgFile(null)
        setSelectedStars(0);
    }

    // fetch order api
    const fetchOrderDetails = async () => {
        try {
            const res = await axios.get(`${baseUrl}/user-order-list/${userId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            })
            console.log(res)
            setOrderList(res.data.data)
        } catch (error) {
            console.log("error fetch my order", error)
        }
    }
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };
    useEffect(() => {
        fetchOrderDetails();
    }, [])
    return (
        <>
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>My Orders</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        My Orders
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="order-sec tp-space pt-0">
                {/* {orderList && orderList.length === 0 ? (
                    <div className='text-center'>
                        <h1>You have no order yet.</h1>
                        <Link to='/shop' style={{ borderRadius: "50px" }} className='thm-btn mt-2'>Continue Shopping</Link>
                    </div>
                ) : ( */}
                <div className="container">
                    {
                        orderList && orderList.map((order, index) => {
                            return (
                                <div key={order._id} className="order-card">
                                    <div className="order-card-head">
                                        <p className="mb-0">
                                            <span className="badges">Order: #883475</span> Order Placed: {formatDate(order.order_date)}
                                        </p>
                                        <Link to="/orderdetails" className="thm-btn">
                                            Track order
                                        </Link>
                                    </div>
                                    {
                                        order?.products?.map((prod, index) => {
                                            return (
                                                <div className="order-card-mddle">

                                                    <div className="order-card-bx">
                                                        <img src={thumb} alt="thumb" />
                                                        <div className="order-card-bx-content">
                                                            <h6>Ground Nuts Oil Snack</h6>
                                                            <p>
                                                                50gm <br /> QTY.: 1
                                                            </p>
                                                            <p className="price">
                                                                $28.85 <del>$33.85</del>
                                                            </p>
                                                        </div>
                                                    </div>



                                                    <p className="mb-0 text-success fw-600">
                                                        <em className="fas fa-circle me-1" />
                                                        Shipped on Jan 21 {order.shipped_date}
                                                    </p>
                                                    <p className="mb-0">
                                                        Delivery Expected By:{" "}
                                                        <span className="d-block text-dark">24 Jun 2024 {order.delivery_time}</span>
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="order-card-bttm">
                                        <p className="mb-0">
                                            Order Placed: {formatDate(order.order_date)} | Shipped on Jan 21, 2020 {order.shipped_date} | 10:30 AM {order.delivery_time}
                                        </p>
                                        <div>
                                            <a href="javascript:void(0);" className="thm-btn dark me-2">
                                                Details
                                            </a>
                                            <a href="javascript:void(0);"
                                                className="thm-btn danger"
                                                data-bs-toggle="modal"
                                                data-bs-target="#cancel-order">
                                                Cancel Order{" "}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="order-card">
                        <div className="order-card-head">
                            <p className="mb-0">
                                <span className="badges">Order: #883475</span> Order Placed: 03 Jun
                                2020
                            </p>
                        </div>
                        <div className="order-card-mddle">
                            <div className="order-card-bx">
                                <img src={thumb} alt="thumb" />
                                <div className="order-card-bx-content">
                                    <h6>Ground Nuts Oil Snack</h6>
                                    <p>
                                        50gm <br /> QTY.: 1
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0 text-success fw-600">
                                <em className="fas fa-circle me-1" />
                                Delivered{" "}
                            </p>
                            <div>
                                <p className="mb-0 text-dark">Reviews</p>
                                <p className="rating mb-0">
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fas fa-star" />
                                    <span className="fa-regular fa-star" />
                                </p>
                                <a href="javascript:void(0);" className="txt-org"
                                    data-bs-toggle="modal"
                                    data-bs-target="#order-review">
                                    Write a Review
                                </a>
                            </div>
                        </div>
                        <div className="order-card-bttm">
                            <p className="mb-0">
                                Order Placed: 03 Jun 2020 | Shipped on Jan 21, 2020 | 10:30 AM
                            </p>
                            <div>
                                <a href="javascript:void(0);" className="thm-btn dark me-2">
                                    Details
                                </a>
                                <a href="javascript:void(0);" className="thm-btn">
                                    Repeat{" "}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="order-card">
                        <div className="order-card-head">
                            <p className="mb-0">
                                <span className="badges">Order: #883475</span> Order Placed: 03 Jun
                                2020
                            </p>
                        </div>
                        <div className="order-card-mddle">
                            <div className="order-card-bx">
                                <img src={thumb} alt="thumb" />
                                <div className="order-card-bx-content">
                                    <h6>Ground Nuts Oil Snack</h6>
                                    <p>
                                        50gm <br /> QTY.: 1
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0 text-warning fw-600">
                                <em className="fas fa-circle me-1" />
                                Return{" "}
                            </p>
                            <p className="mb-0">
                                Delivery Expected By:{" "}
                                <span className="d-block text-dark">24 Jun 2024</span>
                            </p>
                        </div>
                        <div className="order-card-bttm">
                            <p className="mb-0">
                                Order Placed: 03 Jun 2020 | Shipped on Jan 21, 2020 | 10:30 AM
                            </p>
                            <div>
                                <a href="javascript:void(0);" className="thm-btn dark">
                                    Details
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="order-card">
                        <div className="order-card-head">
                            <p className="mb-0">
                                <span className="badges">Order: #883475</span> Order Cancelled
                            </p>
                        </div>
                        <div className="order-card-mddle">
                            <div className="order-card-bx">
                                <img src={thumb} alt="thumb" />
                                <div className="order-card-bx-content">
                                    <h6>Ground Nuts Oil Snack</h6>
                                    <p>
                                        50gm <br /> QTY.: 1
                                    </p>
                                    <p className="price">
                                        $28.85 <del>$33.85</del>
                                    </p>
                                </div>
                            </div>
                            <p className="mb-0 text-danger fw-600">
                                <em className="fas fa-circle me-1" />
                                Cancelled{" "}
                            </p>
                            <p className="mb-0" />
                        </div>
                        <div className="order-card-bttm">
                            <p className="mb-0">
                                Order Placed: 03 Jun 2020 | Shipped on Jan 21, 2020 | 10:30 AM
                            </p>
                            <div>
                                <a href="javascript:void(0);" className="thm-btn dark">
                                    Details
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* cancel-order-start */}
            <div
                className="modal fade cancel-modal"
                id="cancel-order"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Cancel Order
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={cancelIcon}
                                    className="img-90"
                                    alt="cancelIcon"
                                />
                            </div>
                            <form action="" onSubmit={handleSubmit}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Reason for Cancellation</label>
                                    <select name="reason"
                                        value={formData.reason}
                                        onChange={handleChange} id="" className="form-select">
                                        <option value="">My Reason is not listed</option>
                                        <option value="reason1">Reason1</option>
                                        <option value="reson2">Reason2</option>
                                    </select>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Write Details</label>
                                    <textarea
                                        name="details"
                                        value={formData.details}
                                        onChange={handleChange}
                                        className="form-control height-100"
                                    // defaultValue={""}
                                    />
                                </div>
                                <div className="payment">
                                    <h5 className="fz-16 fw-600 mb-3">Choose a Refund mode</h5>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="pay-01"
                                            name="payment"
                                            value="credit-card"
                                            checked={formData.payment === 'credit-card'}
                                            onChange={handleChange}
                                        // defaultChecked=""
                                        />
                                        <label className="form-check-label" htmlFor="pay-01">
                                            {" "}
                                            Debit/ Credit Card{" "}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="gpay"
                                            checked={formData.payment === 'gpay'}
                                            onChange={handleChange}
                                            id="pay-02"
                                        />
                                        <label className="form-check-label" htmlFor="pay-02">
                                            G PAY
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="phonepe"
                                            checked={formData.payment === 'phonepe'}
                                            onChange={handleChange}
                                            id="pay-03"
                                        />
                                        <label className="form-check-label" htmlFor="pay-03">
                                            Phone Pe
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment"
                                            value="twint"
                                            checked={formData.payment === 'twint'}
                                            onChange={handleChange}
                                            id="pay-04"
                                        />
                                        <label className="form-check-label" htmlFor="pay-04">
                                            Twint
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="thm-btn w-100 text-center danger"
                                        data-bs-toggle="modal"
                                        data-bs-target="#cancel-request"
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* cancel-order-end */}
            {/* order-cancel-success-start */}
            <div
                className="modal fade cancel-modal"
                id="cancel-request"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={successIcon} alt="successicon" />
                            <p className="text-center text-success fz-20 fw-600 mb-3">
                                Cancel Request submitted Successfully
                            </p>
                            <a
                                href="javascript:void(0);"
                                className="thm-btn"
                                data-bs-dismiss="modal"
                            >
                                Ok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-cancel-success-end */}
            {/*order-review-start */}
            <div
                className="modal fade cancel-modal"
                id="order-review"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Rating &amp; Reviews
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fa fa-times" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <img
                                    src={reviewIcon}
                                    className="img-90"
                                    alt="reviewIcon"
                                />
                            </div>
                            <form action="" onSubmit={handleReviewSubmit}>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">Rate this product</label>
                                    <ul id="stars" className="star-list">
                                        {/* <li className="star selected" title="Poor" data-value={1}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Fair" data-value={2}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Good" data-value={3}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star selected" title="Excellent" data-value={4}>
                                            <i className="fas fa-star fa-fw" />
                                        </li>
                                        <li className="star" title="WOW!!!" data-value={5}>
                                            <i className="fas fa-star fa-fw" />
                                        </li> */}
                                        {[1, 2, 3, 4, 5].map((star) => (
                                            <li
                                                key={star}
                                                className={`star ${selectedStars >= star ? 'selected' : ''}`}
                                                title={star === 1 ? 'Poor' : star === 2 ? 'Fair' : star === 3 ? 'Good' : star === 4 ? 'Excellent' : 'WOW!!!'}
                                                onClick={() => handleStarClick(star)}
                                            >
                                                <i className="fas fa-star fa-fw" />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="custom-frm-bx">
                                    <label htmlFor="">About This product</label>
                                    <textarea
                                        name="review"
                                        value={values.review}
                                        onChange={handleReviewChange}
                                        className="form-control height-100"
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="custom-frm-bx image-upload">
                                    <label htmlFor="image">
                                        <i className="far fa-image me-2" />
                                        Add Photos
                                    </label>
                                    <input type="file" onChange={(e) => setImgFile(e.target.files[0])} id="image" />
                                    {
                                        imgFile && (
                                            <img src={URL.createObjectURL(imgFile)} alt="avtar" />
                                        )}
                                </div>
                                <div className="custom-frm-bx">
                                    <button type='submit' className="thm-btn dark w-100 text-center">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* order-review-end */}
        </>

    )
}

export default MyOrder