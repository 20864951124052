import React, { useEffect, useState } from 'react'
import footerlogo from "../../assets/images/logo.png";
import facebook from "../../assets/images/facebook.svg";
import x from "../../assets/images/x.svg";
import instagram from "../../assets/images/instagram.svg";
import google from "../../assets/images/google.svg";
import snapchat from "../../assets/images/snapchat.svg";
import delivery from "../../assets/images/delivery-icon-01.png";
import delivery2 from "../../assets/images/delivery-icon-02.png";
import delivery3 from "../../assets/images/delivery-icon-03.png";
import delivery4 from "../../assets/images/delivery-icon-04.png";
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseUrl from './BaseUrl';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrency, setSelectedCurrency } from '../features/currencySlice';
const Footer = () => {
    const dispatch = useDispatch();
    const { rates, selectedCurrency, status } = useSelector((state) => state.currency);
    const [settingList, setSettingList] = useState({
        address: "",
        contact: "",
        email: "",
        social_links: "",
    });
    const [email,setEmail] = useState('')
    const fetchSettingList = async () => {
        try {
            const res = await axios.get(`${baseUrl}/web-setting-view`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            console.log("res", res)
             setSettingList(res.data.data)
        } catch (error) {
            console.log("error fetch faq", error)
        }
    }

    useEffect(() => {
        fetchSettingList();
    }, [])

    const handleSubscribe = async() => {
        try {
            const res = await axios.post(`${baseUrl}/news-letter-store`,
                {email:email},
                {
                headers:{
                    Authorization:`Bearer ${localStorage.getItem("token")}`
                }
            })
            // console.log(res)
            if (res.data.status) {
                toast.success(res.data.message)
                setEmail('')
            } else {
                toast.error(res.data.message)
            }
        } catch (error) {
           console.log("error email subscribe",error)
        }
    }

    useEffect(() => {
        dispatch(fetchCurrency());
    }, [dispatch]);
    const handleCurrencyChange = (e) => {
        const newCurrency = e.target.value
        dispatch(setSelectedCurrency(newCurrency));
        dispatch(fetchCurrency(newCurrency)); 
    };
    return (
        <>
            <section className="delivery-option tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery} alt="delivery" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Free Shipping</h5>
                                    <p>limit can be reduced to</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery2} alt="delivery2" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Returns</h5>
                                    <p>Within 30 days for an exchange.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery3} alt="delivery3" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Flexible Payment</h5>
                                    <p>Pay with Multiple Banks, Credit...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="delivery-card">
                                <span>
                                    <img src={delivery4} alt="delivery4" />
                                </span>
                                <div className="delivery-content">
                                    <h5>Online Support</h5>
                                    <p>24 hours a day, 7 days a week</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="theme-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="ftr-abt">
                                <Link to="/" className="ftr-logo">
                                    <img src={footerlogo} alt="footerlogo" />
                                </Link>
                                <p>
                                    We offer high-quality foods and the best delivery service, and the
                                    food market you can blindly trust
                                </p>
                                <div className="media">
                                    <a href="javascript:void(0);">
                                        <img src={facebook} alt="facebook" />
                                    </a>
                                    <a href="javascript:void(0);">
                                        <img src={x} alt="x" />
                                    </a>
                                    <a href="javascript:void(0);">
                                        <img src={instagram} alt="instagram" />
                                    </a>
                                    <a href="javascript:void(0);">
                                        <img src={google} alt="google" />
                                    </a>
                                    <a href="javascript:void(0);">
                                        <img src={snapchat} alt="snapchat" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3">
                            <h4 className="ftr-title">Links</h4>
                            <ul className="ftr-link">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/shop">Shop</Link>
                                </li>
                                <li>
                                    <Link to="/category">Categories</Link>
                                </li>
                                <li>
                                    <Link to="/aboutus">About us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-3">
                            <h4 className="ftr-title">licenses</h4>
                            <ul className="ftr-link">
                                <li>
                                    <a href="javascript:void(0);">FSSAI</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Eating House License</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Health Trade License</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <h4 className="ftr-title">CUSTOMER SERVICE</h4>
                            <ul className="ftr-link">
                                <li>
                                    <Link to="/contactus">Help</Link>
                                </li>
                                <li>
                                    <Link to="/faq">FAQs</Link>
                                </li>
                                <li>
                                    <Link to="/privacypolicy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/cancellationpolicy">Cancellation Policy</Link>
                                </li>
                                <li>
                                    <Link to="/termsconditions">Terms &amp; Conditions</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h4 className="ftr-title">Address</h4>
                            <ul className="ftr-add">
                                <li>
                                    <a href="javascript:void(0);">
                                        <i className="fa fa-home me-2" />
                                        {settingList.address}
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+91-9876543210">
                                        <i className="fa fa-phone  me-2" />
                                        {settingList.contact}
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:eckomart.info@gmail.com">
                                        <i className="fa fa-envelope me-2" />
                                        {settingList.email}
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa-solid fa-link me-2"></i>
                                        {settingList.social_links}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-end align-items-center">
                        <div className="col-lg-3 col-md-4 ">
                            <div className="send-email">
                                <span className="far fa-envelope-open" />
                                <h5>Send me up-to-date information</h5>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <div className="subscribe">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email Address"
                                    name='email'
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                                <button className="thm-btn" type="button" onClick={handleSubscribe}>
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright d-flex justify-content-around">
                    <p className="text-center mb-0 text-white">
                        Copyright © 2024 Object Square All Rights Are Reserved
                    </p>
                    <ul className="top-bar-menu">
                        {/* <li className="top-bar-menu-innr">
                            <div className="dropdown">
                                <button
                                    className=" dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa fa-money-bill-wave-alt me-2" />
                                    USD
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0);">
                                            USD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0);">
                                            INR
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        <select value={selectedCurrency} onChange={handleCurrencyChange}>
                            {Object.keys(rates).map((currency) => (
                                <option key={currency} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </select>
                        <li className="top-bar-menu-innr">
                            <div className="dropdown">
                                <button
                                    className="dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-globe-asia me-2" />
                                    English
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0);">
                                            English
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="javascript:void(0);">
                                            Hindi
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default Footer