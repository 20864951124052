import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import thumb from "../../assets/images/thumb-01.png"
import mainproduct from "../../assets/images/main-product-01.png"
import thumb1 from "../../assets/images/product-img-01.png";
import mainproduct1 from "../../assets/images/product-img-01.png";
import thumb2 from "../../assets/images/product-img-08.png";
import mainproduct2 from "../../assets/images/product-img-08.png";
import thumb3 from "../../assets/images/product-img-12.png";
import mainproduct3 from "../../assets/images/product-img-12.png";
import thumb4 from "../../assets/images/product-img-11.png";
import mainproduct4 from "../../assets/images/product-img-11.png";
import { imgUrl } from '../Helper/BaseUrl';
const ThumbSlider = ({ singleProductData }) => {
    // console.log("object", singleProductData)
    const [activeIndex, setActiveIndex] = useState(0);

    const images = [
        {
            main: mainproduct,
            thumb: thumb,
        },
        {
            main: mainproduct1,
            thumb: thumb1,
        },
        {
            main: mainproduct2,
            thumb: thumb2
        },
        {
            main: mainproduct3,
            thumb: thumb3
        },
        {
            main: mainproduct4,
            thumb: thumb4
        },
    ];

    const mainOptions = {
        items: 1,
        // loop:true,
        nav: false,
        dots: false,
        loop: true,
        // autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        onChange: (event) => {
            setActiveIndex(event.item.index);
        }
    };

    const thumbOptions = {
        items: 4,
        // loop:true,
        nav: false,
        dots: false,
        margin: 10,
        // loop: true,
        // center: true,
        responsive: {
            0: {
                items: 3
            },
            768: {
                items: 4
            }
        }
    };

    const handleThumbClick = (index) => {
        setActiveIndex(index);
        console.log(index)
    };

    return (
        <div className="col-lg-6">
            <div className="product-details-lft">
                <div className="outer">
                    <OwlCarousel
                        className="owl-theme"
                        id="product-main-slide"
                        {...mainOptions}
                        startPosition={activeIndex}
                    >
                        {singleProductData.map((image, index) => {
                            return (
                                <div className="product-main" key={index}>
                                    <img src={`${imgUrl}/${image.image}`} alt={`Product ${index + 1}`} />
                                </div>
                            )
                        })}
                    </OwlCarousel>

                    <OwlCarousel
                        className="owl-theme"
                        id="thumbs-slide"
                        {...thumbOptions}
                        startPosition={activeIndex}
                    >
                        {singleProductData.map((image, index) => (
                            <div
                                className={`product-thumb ${activeIndex === index ? 'active cre' : ''}`}
                                key={index}
                                onClick={() => handleThumbClick(index)}
                            >
                                <img src={`${imgUrl}/${image.image}`} alt={`Thumbnail ${index + 1}`} />
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
};

export default ThumbSlider;
