import React, { useEffect, useState } from 'react'
import cover from '../assets/images/cover-img.png';
import avtar from '../assets/images/avtar.png';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import baseUrl, { imgUrl } from './Helper/BaseUrl';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { logout } from './features/authSlice';

const Profile = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        // setLoading(true)
        fetchProfile();
    }, [])

    const [values, setValues] = useState({
        name_title:"",
        full_name: "",
        mobile: "",
        email: "",
        country: "",
        state: "",
        city: "",
        gender: "",
        profile_image: '',
    })
    const [loading, setLoading] = useState(true)
    const [imgFile, setImgFile] = useState(null)
    const [imageLoading, setImageLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchProfile = async () => {
        try {
            const res = await axios.get(`${baseUrl}/profile-get`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            // console.log("pro", res.data.data)
            setValues(res.data.data)
        } catch (error) {
            console.log("fetch profile error", error)
        } finally {
            // setLoading(false)
        }
    }
    // console.log(imgFile)
    const customId = "custom-id-yes";
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mobile') {
            if (/^\d*$/.test(value)) {
                const numericValue = value.replace(/[^0-9]/g, '')
                if (numericValue.length > 10) {
                    toast.error("Phone number must be exactly 10 digits", {
                        toastId: customId
                    });
                    return;
                }
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: numericValue
                }));
            } else {
                toast.error('Only digits are allowed.', { toastId: customId });
            }
        } else {
            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    const validateProfile = () => {
        const { name_title, full_name, mobile, email, country, state, city, gender } = values;
        if (!name_title || !name_title.trim()) {
            toast.error('Please Select mr/ms')
            return false;
        }
        if (!full_name || !full_name.trim()) {
            toast.error('Please Enter your full name')
            return false;
        }
        if (mobile === '') {
            toast.error('Please Enter your Phone Number')
            return false;
        }
        if (!/^\d{10}$/.test(mobile)) {
            toast.error('Phone Number must be exactly 10 digits.');
            return false;
        }
        if (!email || !email?.trim()) {
            toast.error('Please Enter Your Email');
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error('Please Enter Valid Email Address');
            return false;
        }
        if (!country || !country.trim()) {
            toast.error('Country Required')
            return false;
        }
        if (!state || !state.trim()) {
            toast.error('State Required')
            return false;
        }
        if (!city || !city.trim()) {
            toast.error('City Required')
            return false;
        }
        if (!gender || !gender.trim()) {
            toast.error('Please Select gender.')
            return false;
        }
        return true;
    }

    // console.log(values)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateProfile()) {
            // setLoading(true)
            const formData = new FormData();
            formData.append('name_title', values.name_title);
            formData.append('full_name', values.full_name);
            formData.append('mobile', values.mobile);
            formData.append('email', values.email);
            formData.append('country', values.country);
            formData.append('state', values.state);
            formData.append('city', values.city);
            formData.append('gender', values.gender);
            if (imgFile) {
                formData.append('profile_image', imgFile);
            }
            try {
                const response = await axios.post(`${baseUrl}/profile-update`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                // console.log('update', response)
                if (response.data.status) {
                    toast.success(response.data.message)
                    setValues((prevValues) => ({
                        ...prevValues,
                        profile_image: response.data.data.profile_image,
                    }));
                    // setLoading(false)
                } else {
                    // setLoading(false)
                    toast.error(response.data.message)
                }

            } catch (error) {
                // setLoading(false)
                console.log("profile update error ", error)
            }
        }
    };

    const handleAccountDelete = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover your profile after deletion!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    // Call the API to delete the profile
                    const res = await axios.delete(`${baseUrl}/profile-delete`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    });
                    // Assuming the response includes a success message
                    if (res.data.status) {
                        toast.success(res.data.message);
                        dispatch(logout());
                        navigate('/')
                    }else{
                        toast.error(res.data.message)
                    }
                } catch (error) {
                    console.log("account delete error", error);
                    toast.error('Failed to delete account');
                }
            }
        });
    };

    const handleImageChange = (e) => {
        setImageLoading(true);
        const file = e.target.files[0];
        setImgFile(file);

        // position: absolute;
        // top: 60px;
        // left: 49 %; 
        setTimeout(() => {
            setImageLoading(false);
        }, 1000);
    };
    return (
        <>
            {/* {
                loading && (
                    <div className="loader-main">
                        <div className="loader1"></div></div>
                )} */}
            <section className="banner-innr mt-150">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="banner-innr-content">
                                <h3>Profile</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Profile
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="profile-sec tp-space pt-0">
                <div className="container">
                    <div className="profile-innr">
                        <div className="cover-img">
                            <img src={cover} alt="cover" />
                        </div>
                        <form action="" onSubmit={handleSubmit}>

                            <div className="profile-upload">
                                <input type="file"
                                    // onChange={(e) => setImgFile(e.target.files[0])}
                                    onChange={handleImageChange}
                                    id="profile" />
                                {imageLoading && (
                                    // <div className="loaderaa"></div>
                                    // <div className='loader-overlay'>
                                        <span className="spinner-border spinner-border-sm lodo"></span>
                                    // </div>
                                )}
                                {/* {
                                    imgFile ? (
                                        <img src={`${imgUrl}/${values.profile_image}`} alt="avtar" />
                                    ) : (
                                        <img src={avtar} alt="avtar" />
                                    )
                                } */}
                                {/* <img
                                    src={imgFile ? URL.createObjectURL(imgFile) :  `${imgUrl}/${values.profile_image}`}
                                    alt="Profile"
                                /> */}
                                <img
                                    src={imgFile
                                        ? URL.createObjectURL(imgFile)
                                        : values.profile_image
                                            ? `${imgUrl}/${values.profile_image}`
                                            : avtar
                                    }
                                    alt="Profile"
                                />
                                <label htmlFor="profile">
                                    <i className="fas fa-pen" />
                                </label>
                            </div>
                            <div className="profile-innr-frm">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Name</label>
                                            <div className="custom-frm-bx-innr">
                                                <select id="" className="form-select" name='name_title' onChange={handleChange} value={values.name_title}>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Ms">Ms</option>
                                                </select>
                                                <input type="text" name="full_name" value={values.full_name} onChange={handleChange} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Mobile Number</label>
                                            <input type="tel" name="mobile" value={values.mobile} onChange={handleChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Email Address</label>
                                            <input type="email" name="email" value={values.email} onChange={handleChange} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Country</label>
                                            <select name="country" value={values.country} onChange={handleChange} id="" className="form-select">
                                                <option value="">Select Country</option>
                                                <option value="India">India</option>
                                            </select>
                                        </div>
                                    </div>
                                  
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">State</label>
                                            <select name="state" value={values.state} onChange={handleChange} id="" className="form-select">
                                                <option value="">Select State</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">City</label>
                                            <select name="city" value={values.city} onChange={handleChange} id="" className="form-select">
                                                <option value="">Select City</option>
                                                <option value="Jaipur">Jaipur</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="custom-frm-bx">
                                            <label htmlFor="">Gender</label>
                                            <select name="gender" value={values.gender} onChange={handleChange} id="" className="form-select">
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button className="thm-btn dark" type="submit">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="text-end mt-3 mb-3">
                        <button onClick={handleAccountDelete} className="thm-btn danger">
                            Delete Account
                        </button>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Profile